.pagination {

  >li:first-child>a,
  >li:first-child>span,
  >li:last-child>a,
  >li:last-child>span {
    border-radius: 0px !important;
  }

  .page-item.active .page-link {
    background-color: $brand-primary-blue-2;
    border-color: $brand-primary-blue-2;
  }

  a {
    color: $brand-primary-blue-1;
  }

  .page-link {
    font-size: 12px;
  }

}

.table-pagination {
  @extend .pagination;

  li>a {
    color: $brand-primary-blue-1;
  }

  .active-page>a {
    background-color: $brand-primary-blue-1;
    color: $white;
  }
}

// Pagination in Login history
.react-bootstrap-table-pagination {
  padding-top: 10px;
  font-size: $base-font-size;
}

.total-count {
  font-size: $font-size-md;
}

.login-history .react-bootstrap-table-pagination {
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  & > div {
    width: auto !important;
  }
  ul {
    margin: 0;
  }

  .pagination .page-item.active .page-link,
  .table-pagination .page-item.active .page-link {
    background-color: $brand-primary-blue-1;
    border-color: $brand-primary-blue-1;
  }
  .page-item:first-child .page-link {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }
  .page-link {
    border-color: #A6A9AC;
  }
}

// Message list pagination

.paging-info {
  font-size: $base-font-size - 1;
  color: $dark-gray;
  padding: 0 5px;
}

.message-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &-arrows {
    margin: 3px 0 13px;

    &>li {

      &>a,
      &>span>li>span {
        border: none;
        color: $dark-gray;
        padding: 0 12px;
        margin-top: 10px;
        background-color: transparent;
      }
    }
  }
}