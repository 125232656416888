@import 'src/styles/variables';

.userPermissionTable {
    table-layout: fixed;
    color: $primary-font-color;
    font-size: max(min(2.1vw, 1rem), 12px);
    line-height: 1rem;
    border-spacing: 0px;
    margin-bottom: 10px;

    thead {
        border-bottom: 2px solid $primary-font-color;

        th {
            padding: 0.5rem 0.3rem;
            font-weight: 400;
        }

        th.widthAuto {
            width: 100%;
        }

        th.actions {
            width: 320px;
        }
    }

    tbody {
        .usersLoadingArea {
            vertical-align: middle;
            color: $primary-blue !important;
            cursor: default;

            .spinner-border {
                border-right-color: lightgray;
                border-bottom-color: lightgray;
                border-left-color: lightgray;
            }

            .usersLoadingText {
                color: rgb(136, 136, 136);
                margin-left: 6px;
            }
        }

        tr.userRow {
            position: relative;

            td {
                padding: 4px 4px 3px;
                vertical-align: middle;
            }

            @media (max-width: 500px) {
                td {
                    padding: 3px 2px 0px 3px;
                    vertical-align: middle;
                }
            }

            td.fullName {
                font-weight: 700;
            }

            td.widthAuto {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            td.actionsColData {
                font-size: max(min(1.9vw, 1rem), 12px);
                padding: 4px 12px 3px 4px;

                .dropdown-toggle::after {
                    display: none !important;
                }

                .alignActionButtons {
                    display: flex;
                    align-items: center;
                }

                button {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    color: $brand-primary-blue-1;
                    font-weight: 400;
                }

                button.grantAccess {
                    font-size: 14px;
                    line-height: 1.5;
                    width: 150px;
                    height: 32px;
                    padding: 4px 8px;
                    border: 1px solid #898D91;
                    border-radius: 2px;
                    margin-right: 9px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #212529;
                    
                    svg {
                        color: #669440;
                        width: 22px;
                        height: 22px;
                        margin-right: 9px;
                    }
                }
                
                button.denyAccess {
                    font-size: 14px;
                    line-height: 1.5;
                    width: 150px;
                    height: 32px;
                    padding: 4px 8px;
                    border: 1px solid #898D91;
                    border-radius: 2px;
                    margin-right: 9px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #212529;

                    svg {
                        color: #CC4A43;
                        width: 22px;
                        height: 22px;
                        margin-right: 9px;
                    }
                }
            }
        }
    }

    .clickableColumns {
        cursor: pointer;
    }
    
    .sortIcon {
        color: #6B7075;
        font-size: 11px;
    }
}

.userPermissionTablePopup .show-grid {
    min-height: 0 !important;
    height: auto !important;
}
