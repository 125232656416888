@import 'src/styles/variables';

.asterisk {
    color: $primary-error;
}

.api_endpoint_modal label{
    font-size: $font-size-md;
    padding: 0 2px 4px 2px;
} 

.close_icon {
    color: #FFF;
    width: 26px;
    line-height: 24px;
    cursor: pointer;
}