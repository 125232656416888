.gfrProductName {
    color: #ff9000;
    font-size: 32px;
    text-transform: uppercase;
    line-height: 39px;
    display: inline-block;
}

.trProductName {
    font-size: smaller;
    font-weight: bold;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

// Variables for colors
$primary-color: #007bff;
$text-color: #ee1f1f;
$border-color: #ccc;

.formGroup {
    margin-bottom: 10px;

    label {
        display: block; 
        margin-bottom: 5px; 
    }
    &.checkboxGroup {
        display: flex;
        align-items: center; 

        input[type='checkbox'] {
            width: 20px; 
            height: 20px;
            margin-right: 10px; 
            accent-color: $primary-color;
        }

        label.customCheckbox {
            font-size: 13px; 
            color: $text-color; 
            cursor: pointer; // Show pointer on hover
        }
    }
}