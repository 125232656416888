
.action_button_wrapper {
    .action_button {
        margin: 1rem 0;
        background-color: #0973BA;
        color: #fff;
    }
}

.taxSoftwares {
    padding-top: 1rem;

    div {
        display: flex;
        align-items: center;
        padding-bottom: 3px;

        label {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            margin-left: 0.5rem;
        }
    }
}
