.company_details_content {
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
	margin-top: 2rem;

	span {
		font-weight: 700;
	}
}

:global .alert {
	border-radius: 0;
}

.action_button_wrapper {
	.company_details_action_buttons {
		display: flex;
		color: #0973ba;
		border: 1px solid #0973ba;
		padding: 6.5px 24px;
		font-size: 14px;
		margin-top: 1rem;

		svg {
			margin-right: 8px;
		}
	}

	:global .button {
		margin-left: 0px;
		width: 100px;
	}
}

.horizontal_divider {
	color: #6b7075;
}

.location_header {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
}

.office_list_container {
	margin: 1rem 0;
	display: grid;
	grid-template-columns: 23% 23% 23% 23%;
	column-gap: 2.5%;
	row-gap: 24px;
}

.navigatorContainer {
	display: flex;
	align-items: center;
	border-radius: 4px;
	float: right;

	.navAction {
		cursor: pointer;
		margin-right: 1px;
		border: 1px solid #eaecef;
	}

	.pageNumbers {
		height: 38px;
		width: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 1rem;
		margin-right: 1px;
		background-color: #0973ba;
		border: 1px solid #0973ba;
		cursor: default;
	}

	.disabled_nav {
		cursor: not-allowed;
		background-color: #eaecef;

		svg {
			color: #abadaf;
		}
	}

	svg {
		color: #0973ba;
		margin: 10px;
	}
}

.deleteOfficeLocationModal {
	:global .modal-dialog {
		width: 550px;
		max-width: 550px;

		.container {
			font-size: 1rem;
			color: #212529;

			:global .dataPresentMessage {
				padding-bottom: 5rem;

				.warningMessage {
					margin: 2rem 0;
					padding: 1rem;
					border: 1px solid #ab7f2d;
					border-radius: 4px;
					background: #fbf5eb;
					color: #564016;
					font-size: 1rem;
					font-weight: 400;
					display: flex;
					align-items: center;

					.warningSymbol {
						margin-right: 1rem;
					}

					.warningText {
						color: #212529;

						header {
							font-weight: 700;
							color: inherit;
							background-color: inherit;
							margin: 0;
							padding: 0;
						}

						.bodyText {
							span {
								text-decoration: underline;
							}
						}
					}
				}

				.selectOfficeLocationWrapper {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					position: relative;

					.selectOfficeLocationTitle {
						font-size: 14px;
						font-weight: 600;
						width: 160px;
					}

					.officeLocationOptionsError {
						.officeLocationOptions__control {
							border: 1px solid #cc4a43;
						}
					}

					.selectOfficeLocationData {
						margin-left: 3rem;
						width: 225px;

						.officeLocationOptions__control {
							height: 31px;
							min-height: 31px;
							border-radius: 2px;
							cursor: pointer;

							.officeLocationOptions__value-container {
								height: 31px;

								.officeLocationOptions__placeholder {
									color: #a6a9ac;
									font-size: 14px;
									font-weight: 400;
								}

								.eachOption {
									list-style: none;
									font-size: 14px;
									font-weight: 400;
									height: 22px;
									display: flex;
									cursor: pointer;

									.officeLocationName {
										color: #212529;
										max-width: 50%;
										width: auto;
										white-space: nowrap;
										text-overflow: ellipsis;
										overflow: hidden;
									}

									.officeLocationId {
										color: #6b7075;
										min-width: 48%;
										width: auto;
										white-space: nowrap;
										text-overflow: ellipsis;
										overflow: hidden;
									}
								}
							}

							.officeLocationOptions__indicators {
								height: 31px;

								.officeLocationOptions__indicator {
									height: 31px;
								}
							}
						}

						.officeLocationOptions__menu {
							margin-top: -1px !important;
							min-height: 36px;
							max-height: 89px;
							margin: 0px;
							border: 1px solid #898d91;
							border-radius: 0px 0px 2px 2px;
							overflow-y: hidden;
							overflow-x: hidden;
							scrollbar-width: thin;
							outline: none;
							box-shadow: none;

							.eachOption {
								list-style: none;
								font-size: 14px;
								font-weight: 400;
								height: 26px !important;
								display: flex;
								cursor: pointer;
								padding: 3px 0px;
								margin: 0px !important;

								.officeLocationName {
									color: #212529;
									max-width: 50%;
									width: auto;
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
									padding-left: 12px;
								}

								.officeLocationId {
									color: #6b7075;
									min-width: 48%;
									width: auto;
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
									padding-right: 12px;
								}
							}
						}
						.officeLocationOptions__menu .officeLocationOptions__option {
							padding: 0px !important;
						}

						.officeLocationOptions::-webkit-scrollbar:vertical {
							width: 6px;
						}
					}

					.selectOfficeLocationError {
						position: absolute;
						top: 33px;
						left: 208px;
						color: #cc4a43;
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}

		.modal-footer {
			button {
				width: 125px;
				height: 38px;
			}

			.btn-secondary {
				color: #212529;
			}

			.btn-danger {
				border: 1px solid #cc4a43 !important;
				background: #cc4a43;
			}
		}
	}
}
