.officeInformation {
    font-family: Mulish;
    padding: 0 71px;
    width: 100%;

    .address {
        display: flex;
        width: 625px;
    }

    .address>div:first-child {
        flex-grow: 2;
        margin-right: 25px;
    }

    .cityStateZip {
        display: flex;
        justify-content: space-between;
        width: 625px;

        select {
            outline: none;
            background-color: #FFFFFF;
        }

        select option:first-child {
            display: none;
        }
    }

    .phoneFax {
        display: flex;
        justify-content: flex-start;

        .phone,
        .fax {
            margin-right: 24px;
            position: relative;

            .flag {
                position: absolute;
                padding: 4px 8px;
                background-color: #F0F1F1;
                left: 1px;
                top: 25px;
                height: 29px;
                border-radius: 3px 0 0 3px;
                border-right: 1px solid #898D91;
                display: flex;
                align-items: center;
            }

            input {
                padding-left: 45px;
            }
        }
    }
}