.paymentsTableSection {
    padding-top: 1.6rem;

    .paymentHistoryTable {
        table-layout: fixed;
        color: #212529;
        font-size: max(min(2.1vw, 1rem), 12px);
        line-height: 1rem;
        border-spacing: 0px;
        margin-bottom: 10px;

        thead {
            border-bottom: 2px solid #212529;

            th {
                font-weight: 400;
                font-size: 1rem;
                padding: 0.5rem 0.3rem;
            }

            .dateCol {
                width: 8.5%;
                cursor: pointer;
            }

            .descriptionCol {
                width: 14.5%;
                cursor: pointer;
            }

            .amountCol {
                width: 11.5%;
                cursor: pointer;
            }

            .quantityCol {
                width: 11.5%;
                cursor: pointer;
            }

            .invoiceCol {
                width: 11.5%;
                cursor: pointer;
            }

            .receiptCol {
                width: 11.5%;
                cursor: default;
            }
        }

        tbody {
            .noData {
                font-size: 14px;
            }

            .paymentRow {
                cursor: default !important;

                td {
                    vertical-align: middle;
                    padding: 0.3rem
                }

                .dateColData,
                .descriptionColData,
                .amountColData,
                .quantityColData,
                .invoiceColData,
                .receiptColData {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-weight: 400;
                    font-size: 1rem;
                }

                .receiptColData {
                    span {
                        display: inline-flex;
                        align-items: center;
                        cursor: pointer;
                        margin-top: 1px;

                        .viewReceiptText {
                            margin: -1px 0 0 0.5rem;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .paymentTablePagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;

        .paginationDetails {
            font-size: 1rem;
            font-weight: 400;

            .paymentPageFirst,
            .paymentPageLast,
            .paymentPageTotal {
                font-weight: 700;
            }
        }

        .showPageNumber {
            color: #0973BA;
            display: flex;
            align-items: center;
            padding: 0;
            margin-bottom: 0px;
            margin-right: 0.5rem;

            li {
                list-style: none;
                padding: 6px 6px;
                border: 1px solid #F0F1F1;
                display: flex;
                align-items: center;
                margin-right: 1px;
                cursor: pointer;
                font-weight: bold;

                span {
                    width: 15px;
                    height: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .paginationActionDisabled {
                color: #C4C6C8;
                background-color: #F0F1F1;
                cursor: not-allowed;
            }

            .currentPageNumber {
                background-color: #0973BA;
                border: 1px solid #0973BA;
                color: white;
                font-weight: normal;
                padding: 3px 9px;
                cursor: default;
            }
        }
    }
}