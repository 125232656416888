.filterOfficeLocations {
	.user-popover {
		margin-bottom: 2px;
	}

	.text-ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.overlay-popover {
		background-color: #000;
		color: #fff;
		border-radius: 4px;

		&_text {
			margin: 4px 8px;
		}

		.popover-arrow::after,
		.popover-arrow::before {
			border-bottom-color: #000;
		}
	}

	.value-container {
		width: 175px;
		margin-bottom: 2px;
		display: flex;
		align-items: center;

		&_label {
			max-width: 68px;
			margin-right: 3px;
			color: #212529;
		}

		&_value {
			color: #6b7075;
		}
	}

	.option-container {
		display: flex;
		align-items: center;
		margin-left: 4px;
		margin-bottom: 3px;

		&_input {
			margin-right: 8px;
			width: 16px;
			height: 16px;
			cursor: pointer;
		}

		&_label {
			font-size: 16px;
			line-height: 1.5;
			font-weight: 400;
			display: flex;
			align-items: center;
			width: 188px;
			cursor: pointer;

			&--name {
				max-width: 74px;
				color: #212529;
			}

			&--selectAll {
				font-size: 14px;
				color: #898d91;
			}

			&--value {
				color: #6b7075;
			}

			&--hyphen {
				margin-left: 3px;
				margin-right: 3px;
				color: #212529;
			}
		}
	}

	.disabled-dropdown {
		pointer-events: none;
		opacity: 0.65;
	}

	.cursor-nodrop {
		cursor: no-drop;
	}

	.filterIcons {
		svg:nth-child(1) {
			width: 17px;
			height: 30px;
			margin-right: 8px;

			path {
				fill: #05386b !important;
			}
		}

		svg:nth-child(2) {
			width: 16px;
			height: 30px;
		}

		&_transformed {
			svg:nth-child(2) {
				transform: rotate(180deg);
			}
		}

		&_marginTop {
			margin-top: 2.5px;
		}
	}

	.menuComponent {
		padding: 11px 16px;
		border-bottom: 1px solid #ccc;
		display: flex;
		justify-content: space-between;

		&_headerText {
			font-size: 16px;
			font-weight: 700;
			line-height: 1.5;
		}

		&_applyBtn {
			margin-right: 12px;
			color: #0973ba;
			font-weight: 500;
			line-height: 1.5;
			cursor: pointer;
		}

		&_clearAll {
			color: #0973ba;
			font-weight: 500;
			line-height: 1.5;
			cursor: pointer;
		}
	}

	.menuListComponent {
		margin-left: 15px;
		margin-top: 8px;
		margin-bottom: 5px;
		font-size: 16px;
		font-weight: 700;
		line-height: 1.5;
	}
}
