.primary_admin_wrapper {
	margin-top: 3rem;
}

.primary_admin_content {
	font-size: 16px;
	font-weight: 400;
	margin-top: 1.5rem;

	span {
		font-weight: 700;
	}
}

.sectional_header {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
}

.primary_admin_card_wrapper {
	display: grid;
	grid-template-columns: 23% 23% 23% 23%;
	column-gap: 2.5%;
	row-gap: 24px;
	margin: 1rem 0;

	.primary_admin_cards_container {
		border: 1px solid #c4c6c8;
		border-radius: 4px;
		min-width: 250px;
		width: auto;
		height: 200px;
		position: relative;
		overflow: hidden;

		&.full_height_container {
			height: 284px;
		}

		.primary_admin_card_header {
			display: flex;
			padding: 14px 16px;
			justify-content: space-between;

			.primary_admin_card_left {
				width: 93%;
			}

			svg {
				cursor: pointer;
			}

			.primary_admin_card_name {
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
				color: #212529;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		.primary_admin_card_content {
			padding: 0 16px;
			color: #212529;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			width: 93%;

			.primary_admin_card_email_address {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		.primary_admin_card_mobile_number {
			padding: 25px 16px;
			color: #212529;
			font-size: 16px;
			font-weight: 400;
			line-height: 23px;
		}

		.primary_admin_card_right {
			display: flex;
			top: 1rem;
			align-items: flex-start;
			padding-top: 5px;

			.adminCardMoreOptions {
				display: initial;

				button {
					border: none;
					background-color: transparent;
					padding: 0;
					color: #05386b;
					font-weight: 400;
					min-width: 0px;
					margin-top: -12px;
					display: initial;
				}
			}

			.adminCardMoreOptions > div {
				width: 120px;
				height: 44px;
				border-radius: 4px;
				background-color: #fff;

				.company_card_header_menu_dropdown_edit {
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					color: #212529;
					padding: 1px 16px;
					cursor: pointer;
				}

				li:hover {
					background-color: rgb(0, 0, 0, 0.075);
				}
			}
		}
	}
}
