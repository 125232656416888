@import 'src/styles/variables';

.bulkOfficeLocationUpdationpopup .modal-dialog {
	display: flex;
	box-shadow: 5px 5px 61px 0px;

	.modal-content {
		border-radius: 4.8px;
		box-sizing: border-box;
		border: none;
		background-color: transparent;
        width :  500px;
        height :  350px;       
        padding: 28px, 0px, 28px, 0px;

		.modal-header {
			background-color: #05386B;
			color: #fff;
			border-bottom: 1px solid #dee2e6;
			line-height: 24px;
			font-size: 20px;
			padding: 9px 1rem;
			border-top-left-radius: 4.8px;
			border-top-right-radius: 4.8px;

			.closeIcon {
				color: #fff;
				width: 26px;
				line-height: 24px;
				cursor: pointer;
			}

			.modal-title {
				line-height: 24px;
				font-size: 20px;
				color: #fff;
				font-weight: 500;				
			}
		}

		.modal-body {
			height: fit-content;
			background-color: #fff;
			font-size: 14px;
			min-height: 5rem;
		}

        .modaltext{         
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
        .selectOfficeLocationWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;

			.selectOfficeLocationError {
                position: absolute;
                top: 53px;
  				left: 182px;
                color: #cc4a43;
                font-size: 14px;
                font-weight: 400;
		 }
        }
		.errorBorder {
			box-sizing: border-box;
			border-radius:2px;			
			border: 1px solid #CC4A43;

			.officeLocationOptions__control{
				border : none;
			}
		}
        .selectOfficeLocationTitle {
            font-size: 14px;
            font-weight: 500;
            width: 180px;
            font-family: Mulish;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            padding-top: 20px;
        }
        .selectOfficeLocationData{
            width:  225px;
            height: 17px;
            top: 146px;
            left: 197px;
            padding: 4px, 12px, 4px, 8px;
            border-radius: 2px;
            border: 1px;   
			
			.officeLocationOptions__placeholder {
				color: #A6A9AC;
				font-size: 14px;
				font-weight: 400;
			}
        }
	
		.modal-footer {
			background: #fff;

			.btn-secondary {
				background: #DEE2E6;
				border: 1px solid $input-border-color;
				border-radius: 2px;
				color: #202223;
				font-size: 1rem;
				font-weight: 400;
			}

			.btn-primary {
				border-radius: 2px;
				color: #ffffff;
				font-size: 1rem;
			}

			.btn-danger {
				font-size: 1rem;
				border: 1px solid $input-border-color;
				border-radius: 2px;
			}

            .okbutton{
                width: 163px;
                height:38px;
                padding: 6px, 12px, 6px, 12px;
                border-radius: 2px;
                border: 1px;
                gap: 8px;
                background-color: #669440;
            }
			.btn-disabled,
			.btn-disbaled {
				font-size: 1rem;
				border: 1px solid $input-border-color;
				border-radius: 2px;
				cursor: not-allowed;
				pointer-events: all;
			}
		}
	}

	.popup_grid_container {
		padding: 0;
	}
	
	.officeLocationContainer{
        display: flex;
	}
	.officeLocationName {
		color: #212529;
		max-width: 50%;
		width: auto;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.officeLocationId {
		color: #6b7075;
		min-width: 48%;
		width: auto;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}


