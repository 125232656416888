@import '../../../../../styles/variables';

.user_groups_container {
    font-family: Mulish, 'sans-serif';
    width: 100%;
}

.user_groups_header_container {
    display: flex;
    width: 100%;
    align-items: center;
}

.user_groups_header_text {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-size: 1rem;
}

.user_groups_header_buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: 22rem;
}

.add_user_button {
    background-color: $primary-blue;
    color: #fff;
    margin: 0;
}

.apply_change_button {
    margin: 0;
}