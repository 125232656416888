@import 'src/styles/variables';

.actionSection {
	width: 416px;
	vertical-align: middle;
	max-width: 416px;
}

.actionDivButtonSection {
	display: flex;
	width: inherit;
	justify-content: space-evenly;
	max-width: 416px;
}

.sectionHeaderAlignments {
	display: flex;
	justify-content: space-between;
	margin-right: 1rem;
}

.applyButtonDiv {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.sortIcons {
	cursor: pointer;
}

.button {
	border: 1px solid #898d91;
	border-radius: 2px;
	padding: 0.2rem 1.5rem;
	font-size: 14px;
	background-color: #ffffff;
	color: #212529;
	width: 100px;
	height: 31px;
	justify-content: center;
}

.addButton {
	@extend .button;
	background-color: #0973ba;
	border: 1px solid #0973ba;
	color: #ffffff;
	padding: 0.2rem;
	height: 38px;
	font-size: 16px;
	margin: 0px;

	svg {
		margin-right: 0.4rem;

		path {
			fill: #fff;
		}
	}
}

.editButton {
	@extend .button;

	svg {
		height: 16px;
		width: 16px;
		margin-right: 0.5rem;
		color: #05386b;
	}
}

.deleteButton {
	@extend .editButton;
	padding: 0.2rem 1.1rem;
}

.deactivateButton {
	@extend .editButton;
	width: 115px;
	padding: 0.2rem 0.2rem;
	justify-content: start;

	svg {
		margin-left: 10px;
	}
}

.activateButton {
	@extend .deactivateButton;

	svg {
		margin-right: 10px;
	}
}

.applyButton {
	@extend .addButton;
	width: 150px;
	height: 38px;
	margin-right: 1rem;
	position: absolute;
	right: 10px;
	top: -20px;
}

.navigatorContainer {
	display: flex;
	align-items: center;
	border-radius: 4px;

	.navAction {
		cursor: pointer;
		margin-right: 1px;
		border: 1px solid #eaecef;
		height: 31px;
		width: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pageNumbers {
		height: 31px;
		width: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 14px;
		margin-right: 1px;
		background-color: #0973ba;
		border: 1px solid #0973ba;
		cursor: default;
	}

	.disabled_nav {
		cursor: not-allowed;
		background-color: #eaecef;

		svg {
			color: #abadaf;
			width: 14px;
		}
	}

	svg {
		color: #0973ba;
		width: 14px;
	}
}

.authoritiesLoadingArea {
	vertical-align: middle;
	color: $primary-blue !important;

	:global .spinner-border {
		border-right-color: lightgray;
		border-bottom-color: lightgray;
		border-left-color: lightgray;
		margin-top: 0.5rem;
	}

	.authoritiesLoadingTextWrapper {
		padding-bottom: 0.3rem;

		.authoritiesLoadingText {
			color: rgb(136, 136, 136);
			margin-left: 6px;
		}
	}
}

.noRecordDiv {
	font-size: 14px !important;
	border-right: none;
	background: transparent;
	font-style: italic;
}

.table_container {
	padding: 1.5rem 1rem 0 0;
	font-size: 16px;

	:global .table-bordered {
		border: 1px solid #c4c6c8;
		background-color: #fff;
	}

	:global .table-striped>tbody>tr:nth-of-type(even)>* {
		--bs-table-accent-bg: var(--bs-table-striped-bg);
		background-color: #f2f2f2;
	}

	:global .table-striped>tbody>tr:nth-of-type(odd)>* {
		--bs-table-accent-bg: #fff;
		--bs-table-striped-bg: #fff;
		background-color: #fff;
	}

	table {
		table-layout: fixed;
		width: 100%;
	}

	th {
		font-weight: 600;
		vertical-align: middle;
		font-size: 14px;
		padding: 15px;

		i {
			margin-left: 5px;
		}
	}

	.stateHeader {
		width: 26%;
	}

	tbody>tr {
		height: 41px;
	}

	tbody tr td {
		font-weight: 400;
		font-size: 16px;
		height: 41px;
		vertical-align: middle;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.stateColumnValue {
		padding-inline-start: 18px;
	}

	.cityColumnValue {
		padding-inline-start: 18px;
	}

	.input {
		padding: 0.2rem;
		width: 250px;
		height: 31px;
		border: 1px solid #898d91;
		border-radius: 5px;
		display: block;
	}

	.searchInput {
		@extend .input;
		margin-top: 6px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23898D91' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
		background-size: 16px 16px;
		background-origin: content-box;
		background-repeat: no-repeat;
		background-position: right;
		padding: 0.4rem 0.6rem;
	}

	.searchCity {
		@extend .searchInput;
		width: 300px;
	}
}

.customDeleteModal {
	:global .modal-dialog {
		box-shadow: 0px 0px 0px 0px !important;

		.modal-content {
			border-radius: 4.8px;
			box-sizing: border-box;
			border: none;
			background-color: transparent;
			width: 450px;
			height: 207px;
			margin: auto;

			.modal-header {
				background-color: #fff;
				color: black;
				border-bottom: 1px solid #dee2e6;
				line-height: 24px;
				font-size: 20px;
				padding: 9px 1rem;
				border-top-left-radius: 4.8px;
				border-top-right-radius: 4.8px;
				height: 57px;

				.closeIcon {
					color: rgba(0, 0, 0, 0.5);
					width: 26px;
					line-height: 24px;
					cursor: pointer;
				}

				.modal-title {
					line-height: 24px;
					font-size: 20px;
					color: #212529;
					font-weight: 700;
					width: 394px;
					font-family: Mulish;
				}
			}

			.modal-body {
				height: fit-content;
				background-color: #fff;
				font-size: 16px;
				min-height: 5rem;
			}

			.modal-footer {
				background: #fff;
				height: 63px;

				.btn-secondary {
					background: #f0f1f1;
					border: 1px solid $input-border-color;
					border-radius: 2px;
					color: #202223;
					font-size: 1rem;
					font-weight: 400;

					height: 38px;
					width: 120px;
				}

				.btn-primary {
					border-radius: 2px;
					color: #ffffff;
					font-size: 1rem;
				}

				.btn-danger {
					font-size: 16px;
					border: 1px solid $input-border-color;
					border-radius: 2px;

					height: 38px;
					width: 120px;

					background-color: #b8433c;
				}

				.btn-disabled,
				.btn-disbaled {
					font-size: 1rem;
					border: 1px solid $input-border-color;
					border-radius: 2px;
					cursor: not-allowed;
					pointer-events: all;
				}
			}
		}
	}
}