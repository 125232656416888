.dragAndDropContainer {
    display: flex;
    justify-content: space-between;
    position: relative;

    article {
        width: 350px;

        span {
            font-family: 'Mulish';
            font-weight: 700;
            font-size: 12px;
            color: #212529;
            margin-right: 6px;

            svg {
                margin-top: -3px;
                cursor: pointer;
            }
        }

        ul {
            padding: 0px;
            border: 1px solid #A6A9AC;
            height: 331px;
            overflow-y: auto;
            margin: 6.5px 0 0 0;
            scrollbar-width: thin;
        }

        ul::-webkit-scrollbar:vertical {
            width: 0.3rem;
        }
    }

    .resetButton {
        position: absolute;
        right: 0px;
        top: -4px;
        display: flex;
        align-items: center;

        .resetIcon {}

        .resetText {
            font-size: 1rem;
            font-weight: 400;
            color: #0973BA;
            margin-left: 0.5rem;
            text-decoration: underline;
        }
    }

    .selectAllSection {
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-left: 8.5px;

        &_input {}
        
        &_label {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            margin-right: 6px;
            margin-left: 8px;
        }
    }
}

.disabled {
    cursor: not-allowed !important;
}