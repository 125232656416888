@import 'src/styles/variables';

.paymentsHeadSection {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .filterSection {
        .filterHeaderTitle {
            display: flex;
            margin: 0 0 3px 0;
            padding: 0;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;

            li {
                list-style: none;
                width: 143px;
            }

            .toText {
                margin-left: 1rem;
            }
        }

        .filterDate {
            display: flex;
            margin: 0;
            padding: 0;

            li {
                list-style: none;
            }

            .fromDate {
                width: 143px;
                position: relative;

                input {
                    width: 100%;
                    height: 38px;
                    border: 1px solid $input-border-color;
                    border-radius: 4px;
                    padding: 0.375rem 0.75rem;
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    color: $primary-font-color;
                }

                .calendarIcon {
                    position: absolute;
                    right: 0.7rem;
                    top: 7px;
                    cursor: pointer;
                }
            }

            .toDate {
                width: 143px;
                margin-left: 1rem;
                position: relative;

                input {
                    width: 100%;
                    height: 38px;
                    border: 1px solid $input-border-color;
                    border-radius: 4px;
                    padding: 0.375rem 0.75rem;
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    color: $primary-font-color;
                }

                .calendarIcon {
                    position: absolute;
                    right: 0.7rem;
                    top: 7px;
                    cursor: pointer;
                }
            }

            .filterButton {
                width: 100px;
                height: 38px;
                margin-left: 1rem;
                padding: 0.375rem 0.75rem;
                border: 1px solid $primary-blue;
                border-radius: 2px;
                color: $primary-blue;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .filterIcon {
                    margin-top: -3px;

                    svg {
                        width: 1rem;
                        height: 1rem;
                    }
                }

                .filterText {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    .rightSection {
        .currentReturnBalance {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            padding: 0;
            margin-top: -3.1rem;
            margin-bottom: 1.4rem;

            li {
                list-style: none;
                min-width: 255px;
                width: auto;
                height: 51px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $primary-font-color;
                color: $primary-font-color;
                background: #FFFFFF;
                border-radius: 4px;
                padding: 1rem;
                font-weight: 400;
                font-size: 1rem;

                span {
                    font-weight: 700;
                    color: $primary-font-color;
                }
            }

            .negativeBalance {
                border: 1px solid #B8433C;
                background: #FAEDEC;
                font-size: 1rem;

                span {
                    font-weight: 700;
                    color: #B8433C;
                }
            }
        }

        .exportPurchase {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            padding: 0;

            li {
                list-style: none;
            }

            .export {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 38px;
                height: 38px;
                border: 1px solid #6B7075;
                border-radius: 2px;
                cursor: pointer;
            }

            .disableExport {
                cursor: not-allowed;
                opacity: 0.4;
            }

            .purchase {
                width: 126px;
                height: 38px;
                margin-left: 1rem;
                background: $primary-blue;
                border: 1px solid $primary-blue;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .dollarIcon {
                    margin-top: -2px;

                    svg {
                        width: 1rem;
                        height: 1rem;
                    }
                }

                .purchaseText {
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    color: #FFFFFF;
                    margin-left: 0.5rem;
                }
            }
        }
    }
}