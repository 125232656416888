@import 'src/styles/variables';

.clientGridHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 31px;

	.searchClient {
		display: flex;
		margin-right: 0.5rem;
		width: 350px;
		height: 38px;
		position: relative;

		input {
			outline: none;
			border: 1px solid $input-border-color;
			padding: 6px 30px 6px 12px;
			width: 100%;
			min-width: 160px;
			border-radius: 4px 0 0 4px;
		}
	}

	.searchIcon {
		border: 1px solid $input-border-color;
		background-color: white;
		width: 45px;
		padding: 10px;
		margin-left: -1px;
		color: gray;
		border-radius: 0 4px 4px 0;

		svg {
			width: 16px;
			height: 16px;
			vertical-align: unset;
		}
	}

	.crossIcon {
		position: absolute;
		top: 50%;
		right: 52px;
		transform: translateY(-50%);
		background: none;
		border: none;
		cursor: pointer;
		color: gray;
	}

	.bulkDeleteClients {
		border: 1px solid #6b7075;
		background-color: rgba(255, 255, 255, 1e-6);
		padding: 10px;
		height: 38px;
		width: 38px;
		color: $primary-font-color;
		border-radius: 2px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.deleteDisabled {
		opacity: 0.65;
		cursor: not-allowed;
	}

	.bulkEditClients {
		border: 1px solid #6b7075;
		background-color: rgba(255, 255, 255, 1e-6);
		padding: 10px;
		height: 38px;
		width: 38px;
		color: $primary-font-color;
		border-radius: 2px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 16px;
			height: 16px;
		}
	}
	.bulkEditClients:disabled {
		cursor: not-allowed;
	}

	.addexport {
		display: flex;
		font-size: max(min(2.3vw, 1rem), 14px);

		.dropdown {
			margin-left: 10px;
			margin-right: 10px;
			border-radius: 2px;
		}

		.addUser {
			border: 1px solid $primary-blue;
			background-color: $primary-blue;
			padding: 7px 20px;
			height: 38px;
			border-radius: 2px;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 150px;

			svg {
				width: 21px;
				height: 24px;
				margin-right: 8px;
			}
		}

		.exportUserDetails {
			border: 1px solid #6b7075;
			background-color: rgba(255, 255, 255, 1e-6);
			padding: 10px;
			height: 38px;
			width: 38px;
			color: $primary-font-color;
			border-radius: 2px;
			margin-right: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			// width: 120px;

			svg {
				width: 16px;
				height: 16px;
				// margin-right: 7px;
			}
		}

		.exportDisabled {
			opacity: 0.65;
			cursor: not-allowed;
		}

		.exportIPLoader {
			color: $primary-blue;
			width: 13px;
			height: 13px;
			border: 2px solid currentColor;
			border-right-color: transparent;
			margin: 2px 8px 0 0;
		}
	}

	@media (max-width: 500px) {
		.addexport {
			display: flex;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 0.5rem;

			button {
				display: flex;
				align-items: center;
				width: 48%;
				justify-content: center;
			}
		}
	}
}

@media (max-width: 500px) {
	.clientGridHeader {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		padding: 10px 0;
	}
}
