.consentdiv {
    text-align: right;

    button {
        display: inline-block;
        margin-left: 10px !important;
    }
}

.consentmsg {
    font-size: medium;
}


.modal_z_index {
    z-index: 1060;
}

.consent_backdrop {
    z-index: 1059;
    --bs-backdrop-opacity: 0.75;
  }