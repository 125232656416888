@import './brand_colors.css';
@import url("https://fonts.googleapis.com/css?family=Roboto");

/*-------- Scroll Bar CSS---Start-- */
/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: inherit;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f6f6f6;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--brand-light-blue);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--brand-light-blue);
}

/*-------- Scroll Bar CSS---End---- */

#pageDropDown {
  display: none;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
  box-shadow: none;
}

.help-icon {
  font-size: 18px;
  color: #ffdb6f;
}

.help-icon:hover,
.help-icon:focus {
  color: #ffaa00;
}

input:focus {
  outline: none;
}

.form-control[readOnly] {
  cursor: not-allowed;
  background-color: #fefeff;
  box-shadow: none;
}

.form-control:disabled {
  cursor: not-allowed !important; 
}

.wrapper {
  width: 100%;
  display: table;
  height: 100%;
  table-layout: fixed;
}

.disabled {
  cursor: not-allowed !important;
}

.disabled>div {
  cursor: not-allowed !important;
}

a.btn-default input[disabled] {
  cursor: not-allowed;
}


#product-access-denied-btn{  
  border-radius: 4px !important;
  background-color: #0973BA;
  cursor: pointer;                              
  border: 1px;
  width: 220px;
  height: 38px;
  padding: 0;
  font-size: medium;
}

.product-access-denied{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  align-self: center;    
}

.product-access-denied-text{
  color: #212529; 
  height: 84px;
  width: 817px; 
  font-size: 18px;
  font-family: Mulish;    
}

.product-access-denied-text > div{   
  font-weight: 400 ;
}

.product-access-denied-text > h5{   
  font-weight: 700 ;
  font-size: 18px;
}

.access-denied-wrapper{
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  text-align: center;     
}

.access-denied{
  align-self: center; 
}

.access-denied-text{
  color: #212529; 
  height: 87px; 
  width: 589px; 
  font-size: 18px; 
  font-family: Mulish;
}

.access-denied-text > div{
  font-weight: 400 ;
}

.access-denied-text > h5{
  font-weight: 700 ;
  font-size: 18px; 
}
.W210{
    width:210px;
}