@import 'src/styles/variables';

.developer_section table thead tr {
    border-bottom : 2px solid #202428;
}

.developer_section table>:not(caption)>*>* {
    padding: 0.3rem 0.2rem;
}

.align_action_buttons {
    display: flex;
    align-items: center;
}

button {
    border: none;
    background-color: transparent;
    padding: 0;
    color: $brand-primary-blue-1;
    font-weight: 400;
}

.divider svg {
    width: max(min(1.3vw, 1rem), 6px);
}

.table_header {
    color : $brand-primary-blue-2;
    font-size: $heading-size-1;
}

.download_icon {
    padding: 2px 5px 5px 5px;
    border : 1px solid $brand-primary-blue-1;
    float: right;
}


.btn_status {
    border-radius :4px;
    width: 85px;    
}

.btn_active {
    color: #196C39;
    background-color: #E9F3ED;
    border: 1px solid #196C39;
    cursor: default !important;
}

.btn_resume {
    background-color: #FBF0E7;
    border: 1px solid #A9570F;
    color: #A9570F;
}

.btn_stopped {
    background-color: #FAEDEC;
    border:  1px solid #8F342F;
    color: #8F342F;
    cursor: default !important;
}

