.show {
    display: block;
}

.hide {
    display: none;
}

.groups_header_container {
    max-width: calc(100vw - 20rem);
    width: 100%;
    margin: 1.5rem 0.5rem;
}