@import 'src/styles/variables';

.signatureDelegationModal {
    display: flex !important;
    height: auto;
    max-height: 100%;
    top: 0vh;

    .modal-dialog {
        display: flex;
        width: 787px;
        max-width: 787px;
        margin: 1.75rem auto;
        box-shadow: 3px 3px 10px;

        .modal-content {
            border-radius: 4.8px;
            box-sizing: border-box;
            border: none;
            background-color: transparent;

            .modal-header {
                background: #FFF;
                border-bottom: 1px solid #DEE2E6;
                line-height: 24px;
                font-size: 20px;
                padding: 1rem;
                border-top-left-radius: 4.8px;
                border-top-right-radius: 4.8px;

                .closeIcon {
                    color: rgba(0, 0, 0, 0.5);
                    width: 26px;
                    line-height: 24px;
                    cursor: pointer;
                }

                .modal-title {
                    color: $primary-font-color;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 120%;
                }
            }

            .modal-body {
                background: #fff;
                overflow-y: hidden;

                .containerClass {
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    color: $primary-font-color;
                    padding: 0;
                    line-height: 150%;
                    margin-bottom: 1rem;

                    .descriptionText {
                        font-size: 1rem;
                        color: #212529;
                    }

                    .usersContainer {
                        display: flex;
                        justify-content: space-between;
                        padding: 1rem;

                        article {
                            width: 350px;

                            span {
                                font-family: 'Mulish';
                                font-weight: 700;
                                font-size: 12px;
                                color: #212529;
                                margin-right: 6px;

                                svg {
                                    margin-top: -3px;
                                    cursor: pointer;
                                }
                            }

                            .list_container {
                                padding: 0px;
                                border: 1px solid #A6A9AC;
                                height: 331px;
                                overflow-y: auto;
                                margin: 0.6rem 0 0 0;
                                scrollbar-width: thin;
                            }

                            .list_container::-webkit-scrollbar:vertical {
                                width: 0.3rem;
                            }
                        }
                    }

                    .disabledItem {
                        cursor: not-allowed !important;
                        opacity: 0.6;
                        pointer-events: none;
                    }

                }
            }

            .modal-footer {
                background: #fff;

                .signatureDelegationReset {
                    background-color: #FFF !important;
                    border: none !important;
                    color: $primary-blue !important;
                    text-decoration: underline;
                }

                .btn-primary {
                    background: $primary-blue;
                    border: 1px solid $primary-blue;
                    border-radius: 2px;
                    padding: 6px 12px;
                    color: #FFFFFF;
                    font-size: 1rem;
                }

                .btn-secondary {
                    background: #F0F1F1;
                    border: 1px solid #898D91;
                    padding: 6px 12px;
                    border-radius: 2px;
                    color: $primary-font-color;
                    font-size: 1rem;
                }
            }
        }
    }
}