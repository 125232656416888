.defaultContactPersonSection {
    padding: 0 50px;
    min-height: 390px;

    .defaultcontactPersonDropdown {
        margin-top: 0px;
        padding-left: 0px;
        width: 382px;
    }

    .defaultcontactPersonRowWrapper {
        display: flex;
        align-items: center;
        padding-right: 0px;
        gap: 10px;
        margin-top: 30px;

        .defaultcontactPersonLabel {
            display: flex;
            align-items: center;
            padding-right: 0px;

            .title {
                font-weight: 600;
                font-size: 14px;
                font-family: 'Mulish', sans-serif;
                line-height: 21px;
                white-space: nowrap;
            }
        }
    }

    .noDefaultContactPersonError {
        color: #CC4A43;
        font-size: 14px;
        margin-top: 5px;
        font-family: 'Mulish';
        font-weight: 400;
        line-height: 21px;
    }
}