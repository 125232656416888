@import 'src/styles/variables';

.signatureDelegationDragContainer {
    .draggable-list-card-SD {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.25rem 0.75rem !important;
        font-size: 1rem;
        border-bottom: 1px solid $gray !important;

        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: -o-grab;
        cursor: -ms-grab;
        cursor: grab;

        &:nth-child(even) {
            background-color: $light-gray;
        }

        &:hover {
            cursor: -webkit-grab;
            cursor: -moz-grab;
            cursor: -o-grab;
            cursor: -ms-grab;
            cursor: grab;
        }

        &:active {
            cursor: -webkit-grabbing !important;
            cursor: -moz-grabbing;
            cursor: -o-grabbing;
            cursor: -ms-grabbing;
            cursor: grabbing !important;
        }
    }

    .grip-container svg {
        color: #05386B;
        margin-top: -3px;
    }

    .content-container {
        padding: 0 0.4rem;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .selected-card {
        background-color: rgba($primary-blue, .6) !important;
    }
}