.policyRadios {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 0.5rem;

    div {
        margin-right: 1.5rem;
        display: flex;
        align-items: center;

        input {
            margin-right: 0.5rem;
            cursor: pointer;
        }

        label {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            cursor: pointer;
        }
    }
}

.applyPPChanges {
    margin-top: 1.5rem;

    button {
        width: 150px;
        height: 38px;
        border: 1px solid #0973BA !important;
        background-color: #0973BA;
        color: #fff;
        font-weight: 600;
        font-size: 1rem;
        border-radius: 2px;
        margin-left: 0;
    }

    .noPolicyChanges {
        opacity: 0.65;
        cursor: not-allowed;
    }
}