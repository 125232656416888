.select_placeholder {
    color: gray;
}

.dialog_styles{
    width: 496px;
    height: 638px;
}

.modal_styles {

    .modal_header {
        background-color: white;
        border-bottom: 1px solid #DEE2E6;

        .modal_title {
            font-size: 20px;
            font-weight: 700;
            color: #212529;
        }
    }

    .modal_body {
        max-height: 524px;
        overflow-y: auto;

        form {
            label {
                font-size: 14px;
                color: #212529;
                font-family: Mulish;
                font-weight: 600;
            }

            table {
                th {
                    font-size: 14px;
                    color: #212529;
                    font-family: Mulish;
                    font-weight: 600;
                    padding-left: 15px;
                    padding-right: 15px;
                }
                td {
                    font-size: 16px;
                    color: #212529;
                    font-family: Mulish;
                    font-weight: 400;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }

    .modal_footer {
        height: 63px;
    }
}

.index_value_text {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.index_field_div {
    padding-top: 7px;
    padding-bottom: 7px;
}