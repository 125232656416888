.color_display_wrapper {
    display: flex;
    align-items: center;
    margin: 1rem 0 .5rem;
    .color_display_title {
        font-weight: 700;
        font-size: 14px;
        color: rgba(33, 37, 41, 1);
        text-transform: capitalize;
        width: 23%;
    }

    .color_display_palette {
        height: 30px;
        width: 30px;
        border: 1px solid #000;
        border-radius: 4px;
    }
}