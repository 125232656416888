.company_details_wrapper {

	.company_details_content {
		margin: 2rem 0;
		top: 301px;
		left: 274px;

		span {
			font-weight: 500;
			font-size: 16px;
			font-family: Mulish;
			width: 300px;
			text-overflow: ellipsis;
			overflow: hidden;
			max-height: 22px;
			white-space: nowrap;
		}
	}

	.editor_content {
		display: flex;
		flex-direction: row;
	}

	input[type='text'] {
		font-weight: 500;
		font-size: 16px;
		font-family: Mulish;
		width: 300px;
		outline: 0;
		border-width: 0 0 1px;
		text-overflow: ellipsis;
		border-color: lightgray;
	}

	.editBtn,
	.cancelBtn {
		border: 1px solid #0973ba;
		color: #0973ba;
		display: flex;
		font-size: 15px;
		margin: 0 1rem 0 0;
		padding: 6.5px 24px;

		svg {
			margin-right: 8px;
		}
	}

	.updateBtn {
		border: 1px solid #0973ba !important;
		background-color: #0973ba;
		color: #fff !important;
		font-size: 15px;
		padding: 6.5px 24px;
	}

	.margin_left {
		margin-left: 0.3rem;
	}

	.actionBtn {
		width: 100px;
		height: 31px;
	}
}
