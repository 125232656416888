@import "variables";
@import "./components/modal";
@import "./components/typography";
@import "./components/spacings";
@import "./components/button";
@import "./components/form.scss";
@import "./components/pagination.scss";
@import "./components/tables.scss";


// FONT IMPORT
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Mulish', sans-serif;
    font-size: $base-font-size;
    color: $black;
}

main::-webkit-scrollbar:horizontal {
    height: 9px;
}

// Loading screen UI

.loading-screen-container {
    width: 100vw;
    height: 100vh;
    background-color: #F0F1F1;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
    // .text-wrapper {
    //     flex: 1;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     flex-direction: column;
    // }

    .loading-text {
        margin-top: 50px;
    }
}

.flex-column-center-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

html,
body,
#root {
    height: 100%;
    margin: 0;
}

header {
    background-color: $dark-blue;
    color: $white;
    padding: 10px;

    h2 {
        margin: 0;
    }
}

#content-wrapper {
    display: flex;
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $gray;
    padding: 10px;
    font-size: $font-sm;

    a {
        vertical-align: middle;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
    // -webkit-text-fill-color: #212529;
    transition: background-color 5000s ease-in-out 0s;
}

/* Dot-spin css starts*/
/* dot-spin loader css start */
.dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
        12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0),
        -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0),
        -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
    animation: dot-spin 1.5s infinite linear;
}

@keyframes dot-spin {

    0%,
    100% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    12.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
            12.727926px 12.727926px 0 0 #88c656, 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    25% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 0 #88c656, 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    37.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    50% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 0 #88c656, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    62.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 0 #88c656,
            -12.727926px -12.727926px 0 0 #88c656;
    }

    75% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #88c656,
            -12.727926px -12.727926px 0 0 #88c656;
    }

    87.5% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 -5px rgba(152, 128, 255, 0),
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 0 #88c656;
    }
}

input[type='checkbox'],
input[type='radio'] {
    accent-color: #0973BA;
}

.suitePageContent {
    width: 100%;
    padding: 10px 0px 20px 20px;
    color: $primary-font-color;
    font-size: 14px;
    overflow-y: auto;
    overflow-x: visible;
}

.suitePageInnerContent {
    padding-right: 20px;
    overflow-y: visible;
    height: calc(100vh - 115px);
}

.suitePageHeading {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0;
}

.suitePageSection {
    font-family: 'Mulish';
    font-style: normal;
    padding: 1.8rem 0;
}

.suitePageSectionHeader {
    font-weight: 600;
    font-size: 18px;
    color: #0973BA;
    margin: 0;
}

.suitePageSectionSubHeader {
    font-weight: 700;
    font-size: 16px;
    color: $primary-font-color;
    margin: 0;
    padding-top: 1.2rem;
}

.suitePageSectionDescription {
    font-weight: 400;
    font-size: 14px;
    color: $gray-600;
    margin: 0;
    padding: 0.5rem 0;
    line-height: 21px;
}

.switchSettingsBlock {
    padding-left: 0;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #212529;

    .switchSettingsButton {
        margin: 0;
        cursor: pointer;
    }

    .switchSettingsText {
        margin: 0 1rem;
    }

    .switchSettingsIcon {
        display: flex;
        align-items: center;

        svg {
            cursor: pointer;
        }
    }
}

.fontWeight700 {
    font-weight: 700 !important;
}


/* Base styles */
.alert-container {
    // position: fixed;
    top: 1rem;
    right: 1rem;
    //width: 16rem;
    opacity: 0;
    transform: translateY(0);
  }
  
  /* Fade in animation */
  .fade-in {
    animation: fadeIn 300ms ease-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-1rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Fade out up animation */
  .fade-out-up {
    animation: fadeOutUp 500ms ease-in forwards;
  }
  
  @keyframes fadeOutUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-1rem);
    }
  }
  
  /* Utility classes */
  .animate-in {
    animation-fill-mode: forwards;
  }
  
  .animate-out {
    animation-fill-mode: forwards;
  }
  
  .duration-300 {
    animation-duration: 300ms;
  }
  
  .duration-500 {
    animation-duration: 500ms;
  }