@import 'src/styles/variables';

.stateStyle {
	select {
		outline: none;
		cursor: pointer;
	}
	option:first-child {
		display: none;
	}
}

.showGrid {
	border-bottom: 25px solid white;
	padding: 1rem 1rem 0px 1rem;
}

.cityLabel {
	margin-bottom: 2px;
	font-weight: 600 !important;
	height: 21px;
	width: 26px;
	font-size: 14px;
	line-height: 21px;
}

.urlLabel {
	margin-bottom: 2px;
	font-weight: 600 !important;
	height: 21px;
	font-size: 14px;
	width: 136px;
	line-height: 21px;
}

.requiredError {
	border: 1px solid $primary-error !important;
	height: 31px;
	border-radius: 2px;
	width: 400px;
}

.requiredErrorText {
	color: #cc4a43;
	font-size: 14px;
	font-weight: 400;
	margin-top: -3px;
}

.warningMessageDisplayFlex {
	border: 1px solid #ab7f2d;
	background-color: #fbf5eb;
	padding: 16px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	margin-bottom: 19px;
	width: 655px;
	height: 97px;
	.icon {
		width: 16px;
		margin-right: 12px;
	}
	.warningSymbol {
		margin-right: 1rem;
	}
	.text {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 1.5;
		color: #564016;

		.bold {
			font-weight: 700;
		}
	}
}

.paymentURLInfo {
	position: absolute;
	padding-left: 2px;
	margin-top: - 1px;
}

.prefixCheckBox {
	height: 16px;
	font-size: 75%;
	font-weight: 600;
	width: 16px;
	cursor: pointer;
}

.prefixText {
	vertical-align: middle;
	bottom: 69px;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 5px;
	cursor: pointer;

	svg {
		margin-bottom: 4px;
		margin-left: 2px;
	}
}
.activeCheckBox {
	height: 16px;
	padding-top: 0px;
	font-size: 14px;
	width: 16px;
	cursor: pointer;
}

.activeText {
	font-size: 14px;
	vertical-align: middle;
	position: absolute;
	font-weight: 400;
	height: 20px;
	cursor: pointer;
}

.addBtn {
	width: 88px;
	height: 38px;
	margin-top: -4px;
	min-width: 77px;
	height: 38px;
}

.cancelBtn {
	margin-right: 9px;
	width: 77px;
	height: 38px;
	margin-top: -4px;
	min-width: 77px;
}

.http {
	background-color: #c4c6c8;
	height: 31px;
	border-radius: 2px;
	margin-bottom: 1px;
	width: 56px;
	font-size: 14px;
	border: 1px solid #898d91;
	padding: 8px;
}

.prefix {
	margin-top: 20px;
}

.showAssociatedReturnTab {
	color: #59371b;
	font-weight: 700;
}

.footerStyle {
	padding: 12px;
	height: 63px;
	gap: 8px;
}

.urlWrapper {
	flex-wrap: nowrap !important;
	width: 399px !important;

	input {
		height: 31px;
		border-radius: 2px;
		width: 344px !important;
		margin: 0px 0px -6px 0px !important;
	}
}

.disableStateAndCity {
	pointer-events: none;
	opacity: 0.5;
}

:global .associatedDropDownWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	.associatedReturns__control {
		width: 450px;
		border: 1px solid #898d91;
	}
	.associatedReturns__menu {
		margin-top: -2px;
		border-radius: 0px 0px 2px 2px;
		width: 450px;
		border: 1px solid #898d91;
		box-shadow: none;
	}
	.associatedReturns__option {
		background-color: transparent;
	}
}
