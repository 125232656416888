.input-error-message {
    color: $error-color;
}

.input-error input {
    border-color: $error-color !important;
}

.input-with-dropdown {
    select.form-select {
        flex: 0;
        flex-basis: 60px;
        background-size: 12px;
        padding: 4px 20px 4px 8px;
        font-size: 12px;
        background-position: right 5px center;
        background-color: #F0F1F1;
    }
}

.input-with-toggle {
    .input-group-text {
        border: 1px solid #898D91;
        background-color: transparent;
        border-left: none;
        border-top-right-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important;
    }

    .form-control {
        border-right: none;
    }
}

.react-tel-input .form-control {
    width: 100%
}

.form-check-input:checked {
    background-color:  #0973BA;
}