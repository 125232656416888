@import 'src/styles/variables';

.eroSignatureModal {
	display: flex !important;
	height: auto;
	max-height: 100%;
	top: 0vh;

	.modal-dialog {
		display: flex;
		width: 700px;
		max-width: 700px;
		margin: 1.75rem auto;

		.modal-content {
			border-radius: 4.8px;
			box-sizing: border-box;
			border: none;
			background-color: transparent;

			.modal-header {
				background: #fff;
				border-bottom: 1px solid #dee2e6;
				line-height: 24px;
				font-size: 20px;
				padding: 1rem;
				border-top-left-radius: 4.8px;
				border-top-right-radius: 4.8px;

				.closeIcon {
					color: rgba(0, 0, 0, 0.5);
					width: 26px;
					line-height: 24px;
					cursor: pointer;
				}

				.modal-title {
					color: $primary-font-color;
					font-size: 20px;
					font-weight: 700;
					line-height: 120%;
				}
			}

			.modal-body {
				background: #fff;
				overflow-y: auto;

				.container {
					font-family: 'Mulish';
					font-style: normal;
					font-weight: 400;
					font-size: 1rem;
					color: $primary-font-color;
					padding: 0;
					line-height: 150%;
					margin-bottom: 1rem;

					.defaultSignerOption {
						padding-left: 0;
						display: flex;
						align-items: center;

						.defaultSignerButton {
							margin-left: 2.5em;
							cursor: pointer;
						}

						span.label {
							margin-left: 1rem;
							margin-top: 3px;
						}

						span.label::after {
							content: 'Off';
						}

						input:checked ~ span.label::after {
							content: 'On';
						}
					}

					.preview {
						padding-top: 2rem;
					}

					.signature {
						padding-top: 6px;
						display: flex;

						.userscanneruploadsign {
							padding: 2%;
							border: 1px solid;
							border-radius: 9px;
							min-height: 100px;
							min-width: 300px;
							max-width: 300px;
						}

						.uploadBlock {
							margin: 5px 0.5rem 0 2rem;

							a {
								position: relative;
								border: none;
								background: #0973ba;
								border-radius: 4px;
								color: #ffffff;
								padding: 7px 13px;
								text-decoration: none;

								svg {
									margin-right: 9px;
									margin-top: -5px;
								}

								.btnuploadsettings {
									position: absolute;
									z-index: 2;
									top: 0;
									left: 0;
									opacity: 0;
									background-color: initial;
									color: transparent;
									width: 100%;
									height: 100%;
									cursor: pointer;
								}

								input[type='file']::-webkit-file-upload-button {
									/* chromes and blink button */
									cursor: pointer;
								}
							}

							.imageSelectionError {
								color: #cc4a43;
								font-size: 14px;
								margin: 6px 0 0 3px;
							}
						}

						.infoBlock {
							button {
								border: none;
								padding: 4px 3px;
								background: none;
							}
						}
					}

					.signatureUploadError {
						color: #cc4a43;
					}

					.automaticPlacement {
						padding-top: 1rem;
						display: flex;
						align-items: center;

						input {
							margin-right: 0.5rem;
							margin-top: 1px;
						}

						.automaticPlacementText span {
							font-weight: 700;
						}
					}

					.disabledItem {
						cursor: not-allowed !important;
						opacity: 0.6;
						pointer-events: none;
					}

					.allowUsers {
						.inputAndText {
							padding-top: 1rem;
							display: flex;
							align-items: center;

							input {
								margin-right: 0.5rem;
								margin-top: 1px;
							}

							div.allowUsersText span {
								font-weight: 700;
							}
						}

						button {
							border: 1px solid #0973ba;
							background: #0973ba;
							border-radius: 2px;
							color: #ffffff;
							padding: 6px 12px;
							margin-left: 1.3rem;
							margin-top: 1rem;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 6px;
							width: 154px;
							height: 38px;
						}

						.disableSelectUsers {
							cursor: not-allowed;
							opacity: 0.6;
						}
					}
				}
			}

			.modal-footer {
				background: #fff;

				.btn-primary {
					background: $primary-blue;
					border: 1px solid $primary-blue;
					border-radius: 2px;
					padding: 6px 12px;
					color: #ffffff;
					font-size: 1rem;
				}

				.btn-secondary {
					background: #f0f1f1;
					border: 1px solid #898d91;
					padding: 6px 12px;
					border-radius: 2px;
					color: $primary-font-color;
					font-size: 1rem;
				}
			}
		}
	}
}
