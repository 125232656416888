.custom-table {
    font-size: $font-lg;
    tbody {
        font-size: $font-size-md;
    }
}

.login-history .react-bootstrap-table {
    height: 50vh;
    overflow: auto;
    .custom-table {
        thead {
            position: sticky;
            top: 0;
            background-color: $white;
            box-shadow: 0px 1px 0px $black;
        }
    }
}

.tableSelectCheckbox{
    border-radius: 4px;
    border: 1px solid var(--Components-Checkbox-Border, rgba(0, 0, 0, 0.15));
    background: var(--Gray-White, #FFF);
    width: 16px;
    height: 16px;
}