.formFieldWithError {
    margin-bottom: 0.7rem;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .title {
            font-weight: 600;
            font-size: 14px;
            color: #212529;
        }

        .optional {
            font-weight: 600;
            font-size: 12px;
            color: #6B7075;
        }
    }

    input[type='text'] {
        height: 31px;
        border: 1px solid #898D91;
        border-radius: 3px;
        padding: 4px 8px;
        margin: 3px 0;
        font-size: 14px;
        font-weight: 400;
        color: #212529;
    }

    select {
        height: 31px;
        border: 1px solid #898D91;
        border-radius: 3px;
        padding: 4px 12px 4px 8px;
        margin: 3px 0;
        font-size: 14px;
        font-weight: 400;
        color: #212529;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
		background-size: 12px 13px;
		background-position: right 0px top 50%;
		background-repeat: no-repeat;
		background-origin: content-box;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.23517 3.98468C1.27 3.94976 1.31139 3.92205 1.35695 3.90315C1.4025 3.88424 1.45134 3.87451 1.50067 3.87451C1.55 3.87451 1.59884 3.88424 1.6444 3.90315C1.68995 3.92205 1.73134 3.94976 1.76617 3.98468L6.00067 8.21993L10.2352 3.98468C10.27 3.94982 10.3114 3.92216 10.357 3.90329C10.4025 3.88442 10.4514 3.87471 10.5007 3.87471C10.55 3.87471 10.5988 3.88442 10.6444 3.90329C10.6899 3.92216 10.7313 3.94982 10.7662 3.98468C10.801 4.01955 10.8287 4.06094 10.8476 4.10649C10.8664 4.15205 10.8761 4.20087 10.8761 4.25018C10.8761 4.29949 10.8664 4.34832 10.8476 4.39387C10.8287 4.43942 10.801 4.48082 10.7662 4.51568L6.26617 9.01568C6.23134 9.0506 6.18995 9.07831 6.14439 9.09722C6.09884 9.11612 6.05 9.12585 6.00067 9.12585C5.95134 9.12585 5.9025 9.11612 5.85695 9.09722C5.81139 9.07831 5.77 9.0506 5.73517 9.01568L1.23517 4.51568C1.20025 4.48085 1.17254 4.43947 1.15364 4.39391C1.13473 4.34835 1.125 4.29951 1.125 4.25018C1.125 4.20086 1.13473 4.15202 1.15364 4.10646C1.17254 4.0609 1.20025 4.01952 1.23517 3.98468Z' fill='%23898D91'/%3E%3C/svg%3E");
    }

    .errorBorder {
        border: 1px solid #CC4A43 !important;
    }

    .errorMessage {
        color: #CC4A43;
        font-size: 14px;
        font-weight: 400;
    }
}