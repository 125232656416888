.saveOfficeLocationModal {
    .officeLocationContent {
        padding: 2.8rem 0px 0rem;

        .officeUsers {
            padding: 0 1rem;

            .noUserAddedError {
                color: #CC4A43;
                font-size: 14px;
                font-weight: 400;
                margin-top: 3px;
                margin-left: 386px;
            }
        }
    }
}

.officeLocationFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftSideFooter {
        .primaryLocation {
            display: flex;
            align-items: center;

            .check {
                display: flex;
                align-items: center;
                margin-right: 0.6rem;

                input {
                    width: 1rem;
                    height: 1rem;
                }
            }

            .checkText {
                font-size: 14px;
                font-weight: 400;
                color: #212529;
            }
        }

        .backButton {
            background-color: white !important;
            border: 1px solid #0973BA !important;
            color: #0973BA !important;
            width: 125px;
            font-size: 1rem;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                margin-right: 0.6rem;
            }
        }
    }

    .nextButton,
    .cancelButton {
        width: 125px;
        font-size: 1rem;
        font-weight: 400;
    }

    .cancelButton {
        margin-right: 1rem;
        color: #212529 !important;
    }

    .addButton {
        width: 125px;
        font-size: 1rem;
        font-weight: 400;
    }

    .updateButton {
        width: 125px;
        font-size: 1rem;
        font-weight: 400;
    }

    .disabledUpdateButton {
        cursor: not-allowed;
        opacity: 0.65;
    }
}