@import 'src/styles/variables';

.inOfficeSearch {
	display: flex;
	justify-content: center;
	font-size: 24px;
	gap: 113px;
	margin-top: 18vh;

	&_column1 {
		&--heading {
			font-size: 24px;
			font-weight: 500;
			line-height: 32px;
			color: $primary-font-color;
			margin-bottom: 24px;
		}

		&--infoText {
			font-size: 18px;
			font-weight: 400;
			line-height: 27px;
			color: #565a5e;
			margin-bottom: 20px;
		}

		&--searchClient {
			label {
				font-size: 14px;
				font-weight: 600;
				line-height: 21px;
				margin-bottom: 11px;
			}

			input {
				display: block;
				border: 1px solid #898d91;
				border-radius: 2px;
				outline: none;
				padding: 8px 16px;
				font-size: 16px;
				color: #565a5e;
				margin-bottom: 40px;
				width: 360px;
				height: 38px;
			}
		}

		&--searchBtn {
			width: 250px;
			height: 42px;
			padding: 8px 16px;
			background: $brand-primary-blue-2;
			border: 1px solid $brand-primary-blue-2;
			border-radius: 2px;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: $white;

			display: flex;
			justify-content: center;
			align-items: center;

			span {
				margin-left: 8px;
			}

			&:disabled {
				background-color: #0000001a;
				cursor: not-allowed;
				border-color: #0000001a;
			}
		}
	}

	&_column2 {
		padding-top: 28px;
	}

	.searchClient {
		display: flex;
		width: 360px;
		height: 38px;
		position: relative;
		margin-bottom: 40px;

		input {
			outline: none;
			border: 1px solid $input-border-color;
			padding: 8px 30px 8px 16px;
			width: 100%;
			min-width: 160px;
			border-radius: 4px 0 0 4px;
			font-size: 16px;
		}
	}

	.searchIcon {
		border: 1px solid $input-border-color;
		background-color: white;
		width: 45px;
		padding: 10px;
		margin-left: -1px;
		color: gray;
		border-radius: 0 4px 4px 0;

		svg {
			width: 16px;
			height: 16px;
			vertical-align: unset;
		}
	}

	.crossIcon {
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		background: none;
		border: none;
		cursor: pointer;
		color: gray;
	}

	@media (max-width: 900px) {
		gap: 0px;
	}

	@media (max-width: 768px) {
		padding: 0 50px;
	}
}
