@import 'src/styles/variables';

.usageReportTable {
    table-layout: fixed;
    color: $primary-font-color;
    font-size: max(min(2.1vw, 1rem), 12px);
    line-height: 1rem;
    border-spacing: 0px;
    margin-bottom: 10px;

    thead {
        border-bottom: 2px solid $primary-font-color;

        th {
            padding: 0.5rem 0.3rem;
            font-weight: 400;
        }

        th.widthAuto {
            width: 100%;
        }
    }

    tbody {
        .runReportArea {
            height: 300px;
        }

        .usageReportInitialLoad {
            height: 325px;
            cursor: auto;
            background: #eeeeee;

            .loadingArea {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
                .runReportBtn {
                    width: 126px;
                    height: 38px;
                    padding: 6px 12px;
                    background: $brand-primary-blue-2;
                    border: 1px solid $brand-primary-blue-2;
                    border-radius: 2px;
                    margin-bottom: 13px;
                    
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                    color: $white;
                }
                
                .infoText {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                    color: #212529;
                }
            }
        }

        .usageReportLoadingArea {
            vertical-align: middle;
            color: $primary-blue !important;
            cursor: default;

            .spinner-border {
                border-right-color: lightgray;
                border-bottom-color: lightgray;
                border-left-color: lightgray;
            }

            .usageReportLoadingText {
                color: rgb(136, 136, 136);
                margin-left: 6px;
            }
        }

        tr.clientRow {
            position: relative;

            td {
                padding: 4px 4px 3px;
                vertical-align: middle;
            }

            @media (max-width: 500px) {
                td {
                    padding: 3px 2px 0px 3px;
                    vertical-align: middle;
                }
            }

            td.widthAuto {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .clickableColumns {
        cursor: pointer;
    }
    
    .sortIcon {
        color: #6B7075;
        font-size: 11px;
    }
}
