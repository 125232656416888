.toaster_container {
    .toaster {
        margin-top: 3rem;
        width: 474px;
        font-size: 16px;
    }

    .toaster-common {
        font-family: 'Mulish', sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #212529;
        border-radius: 4px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        min-height: 56px;
    }

    .toaster-error  {
        @extend .toaster-common;
        background-color: #FAEDEC;
        border-left: 5px #CC4A43 solid;
    }
    .toaster-warning {
        @extend .toaster-common;
        background-color: #FBF5EB;
        border-left: 5px #D69F38 solid;
    }

    .toaster-success {
        @extend .toaster-common;
        background-color: #E9F3ED;
        border-left: 5px #1F8747 solid;
    }

    button {
        color: inherit;
        height: 100%;
        font-weight: normal;
        font-size: 16px;
        margin: auto;
    }
}