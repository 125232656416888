.retentionPolicySection {
	margin-top: 2rem;

	.policyContainer {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 1rem;
	}

	.header {
		margin-top: 24px;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		color: #212529;
		margin-bottom: 0.5rem;
	}

	.dropdownWithTooltip {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 1rem;

		label {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 600;
			color: #212529;
			width: 110px;
		}

		.dropdown {
			height: 31px;
			width: 110px;
			padding: 4px 12px 4px 8px;
			border: 1px solid #898d91;
			border-radius: 3.2px;
			background-color: #ffffff;
		}

		.dropdownContainer {
			position: relative;
			display: inline-block;

			select {
				width: 110px;
				padding: 8px 0;
				background-color: #fff;
				border-radius: 4px 0 0 0;
				border: 1px solid #898d91;
				border-top: none;
				opacity: 0;
				height: 250px;
				overflow-y: auto;
			}
		}

		.tooltipIcon {
			display: flex;
			align-items: center;
			svg {
				margin-top: -1px;
				cursor: pointer;
			}
		}
	}

	.notifyBeforeContainer {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 1rem;

		label {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 600;
			color: #212529;
			width: 165px;
			white-space: nowrap;
		}

		.dropdown {
			height: 31px;
			width: 144px;
			padding: 4px 12px 4px 8px;
			border: 1px solid #898d91;
			border-radius: 3.2px;
			background-color: #ffffff;
		}

		.dropdownContainer {
			position: relative;
			display: inline-block;

			select {
				width: 144px;
				padding: 8px;
				background-color: #fff;
				border-radius: 4px 0 0 0;
				border: 1px solid #898d91;
				border-top: none;
				opacity: 0;
				height: 192px;
				overflow-y: auto;
			}
		}

		.tooltipIcon {
			display: flex;
			align-items: center;
			svg {
				margin-top: -1px;
				cursor: pointer;
			}
		}
	}
	.clientHubTooltip {
		.tooltip-inner {
			width: 267px !important;
			height: 92px !important;
			text-align: left;
			padding: 4px 8px;
			border-radius: 4px;
			font-family: 'Mulish', sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}
	}

	.buttonContainer {
		display: flex;
		justify-content: flex-start;
		margin-top: 1rem;
		margin-left: -5px;

		.updateButton {
			background-color: #0973ba;
			color: #fff;
			border: 1px solid #0973ba !important;
			width: 180px;
			height: 38px;
			padding: 6px 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 2px;
			font-family: Mulish;
			font-size: 16px;
			font-weight: normal;
			line-height: 24px;
		}
		.updateButton:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}
