@import 'src/styles/variables';

.usersListTable {
    table-layout: fixed;
    color: $primary-font-color;
    font-size: max(min(2.1vw, 1rem), 12px);
    line-height: 1rem;
    border-spacing: 0px;
    margin-bottom: 10px;

    thead {
        border-bottom: 2px solid $primary-font-color;

        th {
            padding: 4px 4px 5px;
            font-weight: 700;
        }

        th.nameCol {
            width: 22.5%;
        }

        th.emailCol {
            width: 27.5%;
        }

        th.userGroupsCol {
            width: 27.5%;
        }

        th.officeLocationsCol {
            width: 22.5%;
        }

        th.actionsCol {
            width: 150px;
        }

        @media (max-width: 500px) {
            th {
                padding: 3px 2px 3px 3px;
                font-weight: 700;
            }

            th.nameCol {
                width: 20%;
            }

            th.emailCol {
                width: 25%;
            }

            th.userGroupsCol {
                width: 25%;
            }

            th.officeLocationsCol {
                width: 20%;
            }

            th.actionsCol {
                width: 15%;
            }
        }

    }

    tbody {
        .usersLoadingArea {
            vertical-align: middle;
            color: $primary-blue !important;
            cursor: default;

            .spinner-border {
                border-right-color: lightgray;
                border-bottom-color: lightgray;
                border-left-color: lightgray;
            }

            .usersLoadingText {
                color: rgb(136, 136, 136);
                margin-left: 6px;
            }
        }

        tr.userRow {
            position: relative;

            .yellow-background {
                background-color: #eff879;
            }

            .nameColumnData {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;
                height: 24px;

                .leftSideName {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    min-width: 100px;
                    height: 18px;

                    svg {
                        position: absolute;
                        left: -5px;
                        top: 5px;
                    }
                }

                .width60 {
                    width: 60%;
                }

                .rightSideIcon {
                    width: 40%;
                    min-width: 15px;
                    font-size: 14px;
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    color: $primary-error;
                    font-weight: 700;

                    .OS365Icon {
                        min-width: 15px;

                        svg {
                            margin-right: 0.3rem;
                        }
                    }

                    .OS365Text {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                }
            }

            td {
                padding: 4px 4px 3px;
                vertical-align: middle;
            }

            @media (max-width: 500px) {
                td {
                    padding: 3px 2px 0px 3px;
                    vertical-align: middle;
                }
            }

            td.nameColData,
            td.emailColData {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            td.userGroupsColData {
                white-space: nowrap;
            }

            .moreGroups {
                color: $brand-primary-blue-1;
                font-size: max(min(2.1vw, 14px), 12px);
                cursor: pointer;
                text-decoration: underline;
                font-weight: 700;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                max-width: 21%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            td.officeLocationsColData {
                white-space: nowrap;
            }

            .moreOfficeLocations {
                color: $brand-primary-blue-1;
                font-size: max(min(2.1vw, 14px), 12px);
                cursor: pointer;
                text-decoration: underline;
                font-weight: 700;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                max-width: 35%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            td.actionsColData {
                font-size: max(min(1.9vw, 1rem), 12px);
                padding: 4px 16px 3px 4px;

                .dropdown-toggle::after {
                    display: none !important;
                }

                .alignActionButtons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                button {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    color: $brand-primary-blue-1;
                    font-weight: 400;
                }

                button.editUserButton {
                    padding: 0;
                }

                button.deleteUserButton {
                    padding: 0;
                }

                .userMoreActions button {
                    min-width: 0px;
                }

                .divider svg {
                    width: max(min(1.3vw, 1rem), 6px);
                }

                .dropdown svg {
                    width: max(min(1.6vw, 1rem), 9px);
                }

                .dropdown-menu {
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    padding: 8px 0px;
                    font-size: max(min(2.1vw, 1rem), 12px);
                    min-width: 0px;
                    cursor: default;

                    li {
                        padding: 4px 1rem;
                        line-height: 150%;
                        cursor: pointer;
                    }

                    li:hover {
                        background-color: rgb(0, 0, 0, 0.075);
                    }

                    .disabledItem {
                        opacity: 0.6;
                        cursor: not-allowed;
                    }
                }
            }

            .group-badge {
                margin-right: 5px;
                padding: 1px 0.5vw;
                font-size: max(min(2.1vw, 14px), 12px);
                font-weight: 400;
                border-radius: 4px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .officeLocation-values {
                margin-right: 5px;
                padding: 1px 0px;
                border-radius: 4px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .max-width26 {
                max-width: 26%;
            }

            .max-width31 {
                max-width: 31%;
            }

            .max-width48 {
                max-width: 48%;
            }

            .max-width65 {
                max-width: 65%;
            }

            .max-width98 {
                max-width: 98%;
            }

            @media (max-width: 500px) {
                .group-badge {
                    margin-right: 2px;
                    padding: 1px 2px;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 4px;
                }

                .officeLocation-values {
                    margin-right: 2px;
                    padding: 1px 2px;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 4px;
                }
            }

        }
    }
}