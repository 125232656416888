@import 'src/styles/variables';
@import '../../../pages/developer-section/index.module.scss';

.float_right {
    float:right;
}

.asterisk {
    color: $primary-error;
}

.test_connection_status
{
    margin-right: 5px;
}

.test_connection_not_loaded{
    color:$brand-primary-blue-1;
}

.icon_error {
    color : $primary-error; 
}

.icon_correct {
    color:$shamrock;
}