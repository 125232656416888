@import 'src/styles/variables';

.ugCheckArea {
    padding: 0 12px;
    overflow-y: auto;
    scrollbar-width: thin;

    .eachUGCheck {
        list-style: none;
        display: flex;
        align-items: center;
        height: 1.5rem;
        font-size: 14px;
        margin: 0.5rem 0;

        .ugCheckbox {
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }

        label {
            color: $primary-font-color;
            font-size: 14px;
        }
    }

    .columnOptionsSection {
        font-size: 16px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 4px;
    }
}

.ugCheckArea::-webkit-scrollbar:vertical {
    width: 0.4rem;
}

.columnOptionsModal .modal-dialog {
    display: flex;
    max-height: calc(100% - 3.5rem);
    width: min(500px, 95vw);
    margin: 1.75rem auto;

    .modal-content {
        border-radius: 4.8px;
        box-sizing: border-box;
        border: none;
        background-color: transparent;

        .modal-header {
            background: #ffffff;
            border-bottom: 1px solid #DEE2E6;
            line-height: 24px;
            font-size: 20px;
            padding: 9px 1rem;
            border-top-left-radius: 4.8px;
            border-top-right-radius: 4.8px;

            .closeIcon {
                color: #808080;
                width: 26px;
                line-height: 24px;
                cursor: pointer;
            }

            .modal-title {
                line-height: 24px;
                font-size: 20px;
                color: #212529;
                font-weight: 700;
            }
        }

        .modal-body {
            background: #fff;
            overflow-y: auto;

            ul {
                margin: 0;
            }
        }

        .modal-footer {
            background: #fff;

            .btn-secondary {
                background: #F0F1F1;
                border: 1px solid $input-border-color;
                border-radius: 2px;
                color: #202223;
                font-size: 1rem;
            }

            .btn-primary {
                background: $primary-blue;
                border: 1px solid $primary-blue;
                border-radius: 2px;
                color: #FFFFFF;
                font-size: 1rem;
            }
        }
    }
}
