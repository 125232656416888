/* THIS CSS IS FOR LOADING SCREEN (old). WILL BE REMOVED ONCE NEW LOADING PAGE ADDED */
.login-container {
  background: #ffffff;
}

.outer-logo-container {
  position: relative;
  background: #ffffff;
  padding: 70px 0;
  -moz-transition: all 550ms ease-in-out;
  -o-transition: all 550ms ease-in-out;
  -webkit-transition: all 550ms ease-in-out;
}
/*These  Will be removed once implementattion of New menu */
.side-menu-icon-placeholder {
    display: none !important;
}
/*These  Will be removed once implementattion of New menu */
.accordion-item {
    width: 99.5% !important;
}
.inner-logo-container {
    position: relative;
    width: 320px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}

.outer-form-container {
  position: relative;
  content: '';
  left: 50%;
  bottom: 0;
  margin-left: -12.5px;
  border-style: solid;
  border-width: 13px 12.5px 0 12.5px;
  border-color: #ffffff transparent transparent transparent;
  -moz-transition: all 550ms ease-in-out;
  -o-transition: all 550ms ease-in-out;
  -webkit-transition: all 550ms ease-in-out;
  transition: all 550ms ease-in-out;
}

.login-form-container {
  text-align: center;
  padding: 15px 10px;
  background-color: #f7f7f7;
  height: 100vh;
}

.daimond {
  position: relative;
  width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  -moz-transition: all 550ms ease-in-out;
  -o-transition: all 550ms ease-in-out;
  -webkit-transition: all 550ms ease-in-out;
  transition: all 550ms ease-in-out;
}

/* Scroll Issue fix for some android devices */
#ssuite-layout-wrapper > main {
	height: calc(100vh - 85px) !important;
	height: calc(100svh - 85px) !important;
}
