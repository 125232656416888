.requestAdmin {
	width: 100%;
	height: 160px;
	background-color: #4caf50;
    padding: 25px 25px;

    &_header {
        margin-bottom: 18px;
    }

    .alert {
        p {
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}
