@import "../../styles/variables";

.container-fluid {
    background-color: #F0F1F1;
}

.top-section {
    background-color: $white;

    .container-sm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 40px;
    }

    .greetings {
        font-size: 24px;
        font-weight: 500;
    }
}

.banner-section {
    .container {
        width: fit-content;
    }

    .container-sm {
        padding: 15px 40px;
        display: flex;
        justify-content: center;
        height: 100%;
        .banner-container {
            width: 1100px;
            height: 270px;
        }
    }

    .alert {
        font-size: 16px;
        background-color: #EBEFF9;
        border-color: $alert-blue;
        color: $black;
        padding: 10px;
        display: flex;
        align-items: center;

        i {
            color: $alert-blue;
        }

        &.alert-dismissible .btn-close {
            padding: 15px;
        }
    }

    .banner-container {
        background-color: #13294B;
        color: $white;

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
            padding: 30px 20px;

            .sub-title {
                font-size: 14px;
            }

            h3 {
                color: $white;
                font-family: 'Mulish', sans-serif;
                font-weight: 800; 
                font-size: 24px;
            }

            .description {
                color: $white;
                font-family: 'Mulish', sans-serif;
                font-weight: 400; 
                font-size: 16px;
            }
        }

        .image-column {
            background-image: url("../../assets/images/globe.svg");
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}  

.product-section {
    .container-sm {
        padding: 15px 40px;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .product-list {
        width: 599px;
        height: 293px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        & > .logo-wrapper:not(:last-child) {
            margin-right: 30px;
        }
    }

    .logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 50px;

        .ellipse {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            background-color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &.disabled {
            opacity: .8;
        }
    }
}

@media (max-width: 768px) {
    .container-sm {

        .top-section &,
        .banner-section &,
        .product-section & {
            padding: 15px 0;
        }
    }

    .banner-section .banner-container .content {
        padding: 20px;
    }

    .product-section .product-list {
        width: 75%;
        margin: 0 auto;
    }
}

.button-primary-green-rounded {
    border: 2px solid #88C656;
    background-color: #13294B !important;
    color: #88C656 !important;
    width: 200px;
    height: 40px;
    padding: 6px 12px;
    gap: 8px;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
}