@import 'src/styles/variables';

.saveUserModal .modal-dialog {
	display: flex;
	max-height: calc(100% - 3.5rem);
	width: min(500px, 95vw);
	margin: 1.75rem auto;

	.modal-content {
		border-radius: 4.8px;
		box-sizing: border-box;
		border: none;
		background-color: transparent;

		.modal-header {
			background: $brand-primary-blue-1;
			border-bottom: 1px solid #dee2e6;
			line-height: 24px;
			font-size: 20px;
			padding: 9px 1rem;
			border-top-left-radius: 4.8px;
			border-top-right-radius: 4.8px;

			.closeIcon {
				color: #99bde1;
				width: 26px;
				line-height: 24px;
				cursor: pointer;
			}

			.modal-title {
				line-height: 24px;
				font-size: 20px;
				color: #ffffff;
			}
		}

		.modal-body {
			background: #ffffff;
			overflow-y: auto;

			.container {
				padding: 0;

				.col-9 {
					flex: 0 0 auto;
					width: 50%;
				}

				.col-3 {
					flex: 0 0 auto;
					width: 16.66666667%;
				}

				.col-6 {
					flex: 0 0 auto;
					width: 33.33333333%;
				}

				@media (min-width: 768px) {
					.col-md-6 {
						flex: 0 0 auto;
						width: 50%;
					}
				}

				.row {
					position: relative;
				}

				input[type='text'],
				input[type='email'] {
					height: 31px;
					font-size: 14px;
				}

				.flexCenter {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.asterisk {
					color: $primary-error;
				}

				.alignMFACol {
					display: flex;
					align-items: flex-start;
					margin-top: 2rem;

					.userEnableMFA {
						margin-right: 0.5rem;
					}

					label {
						margin-top: -4px;
						font-size: 14px;

						svg {
							margin-top: -5px;
						}
					}
				}

				.alignUserInactive {
					display: flex;
					align-items: flex-start;
					margin-bottom: 1rem;

					.userInactive {
						margin-right: 0.5rem;
					}

					label {
						margin-top: -4px;
						font-size: 14px;

						svg {
							margin-top: -5px;
						}
					}
				}

				.personalInformationText {
					font-size: 15px;
					font-weight: 700;
					color: $primary-font-color;
				}

				.requiredTag {
					margin-bottom: 10px;

					&__text {
						color: #212529;
					}
				}

				.form-label {
					margin-bottom: 0.25rem;
					font-size: 14px;
				}

				.mobileSection {
					position: relative;

					label {
						font-size: 14px;
						margin-bottom: 0.25rem;
					}

					.inputCCMobile {
						display: flex;
						justify-content: flex-start;
						width: 100%;
						box-sizing: border-box;
						border: 1px solid $input-border-color;
						border-radius: 2px;
						height: 31px;
						box-sizing: border-box;

						input.form-control {
							padding: 0 0 0 51px;
							border: 0px;
							height: 29px;
						}

						.flag-dropdown {
							background: #f0f1f1;
							border: none;
							border-right: 1px solid #898d91;
							border-radius: 0px;
							width: 23%;

							.selected-flag {
								width: 100%;
							}

							.arrow {
								height: 6px;
								width: 6px;
								border: solid #212529;
								border-width: 0 1px 1px 0;
								transform: rotate(45deg);
								-webkit-transform: rotate(45deg);
								top: 0;
								margin-top: 1px;
								margin-left: 6px;
							}

							.country-list {
								width: 216px;
								top: 21px;
								font-size: 14px;
								left: 1px;
								font-family: 'Mulish', sans-serif;

								.country {
									padding: 6px 0px 6px 7px;
								}
							}
						}
					}

					.userMobile {
						border: none;
						width: 75%;
						height: 29px;
						padding: 0.25rem 0.5rem;
						position: absolute;
						top: 26px;
						left: 24%;
					}
				}

				.isOptional {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.optional_text {
					font-size: 0.75rem;
					color: #6b7075;
				}

				.form-control {
					background: #ffffff;
					border: 1px solid $input-border-color;
					border-radius: 2px;
					padding: 0.25rem 0.5rem;
				}

				.saveUserFieldErrorBorder {
					border: 1px solid $primary-error !important;
				}

				.saveUserFieldErrorMessage {
					color: $primary-error;
					padding: 4px 0 0;
					display: block;
					font-size: 14px;
				}

				.userInactiveError {
					position: absolute;
					margin-top: 10px;
				}

				.noEmailMatch {
					display: none;
					position: absolute;
					top: 26px;
					right: 13px;
					background-color: white;
					height: 29px;
					width: 23px;
					text-align: center;
					padding-top: 3px;
				}

				.showError {
					display: block;
				}

				.ptinInfo svg {
					margin-top: -5px;
				}

				.enableSSODiv {
					display: flex;
					align-items: center;
					margin: 0.7rem 0 1rem;

					.enableSSOLabel {
						font-size: 14px;
						margin-left: 0.5rem;
					}
				}

				.ugTags {
					box-sizing: border-box;
					border: 1px solid $input-border-color;
					border-radius: 2px;
					padding: 2px 2.5px;
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					overflow-y: auto;
					max-height: 72px;
					min-height: 35px;
					font-size: 14px;
					scrollbar-width: thin;

					.eachUGTag {
						margin: 2px 2.5px;
						border-radius: 4px;
						padding: 1px 8px;
						white-space: nowrap;
						height: 26px;

						.tagDeselect {
							margin-left: 2px;
							cursor: pointer;

							svg {
								width: 15px;
							}
						}
					}
				}

				.ugTags::-webkit-scrollbar:vertical {
					width: 0.4rem;
				}

				.searchUserGroupDiv {
					border: 1px solid $input-border-color;
					display: flex;
					align-items: center;
					padding-right: 8px;
					border-radius: 2px 2px 0px 0px;

					.searchUserGroup {
						border: none;
						box-shadow: none;
						display: inline-block;
						width: 96%;
					}
				}

				.ugCheckArea {
					border: 1px solid #898D91;
					box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
					border-radius: 0px 0px 2px 2px;
					padding: 6px 12px;
					height: 7rem;
					overflow-y: auto;
					scrollbar-width: thin;

					.eachUGCheck {
						list-style: none;
						display: flex;
						align-items: center;
						height: 1.5rem;
						font-size: 14px;
						width: max-content;

						.ugCheckbox {
							margin-right: 10px;
						}

						label {
							color: $primary-font-color;
							font-size: 14px;
						}
					}
				}

				.ugCheckArea::-webkit-scrollbar:vertical {
					width: 0.4rem;
				}
			}
		}

		.modal-footer {
			background: #fff;

			.btn-secondary {
				background: #f0f1f1;
				border: 1px solid $input-border-color;
				border-radius: 2px;
				color: #202223;
				font-size: 1rem;
			}

			.btn-primary {
				background: $primary-blue;
				border: 1px solid $primary-blue;
				border-radius: 2px;
				color: #ffffff;
				font-size: 1rem;
			}
		}
	}
}
