@import '../../../styles/variables';

.accordian_list_container {
    width: 100%;

}
.accordian_list {
    border-bottom: 2px solid #ddd;

}

.accordian_list_title {
    font-size: 1rem;
    display: flex;
    width: 100%;
    padding: .5rem 1rem;

}
.accordian_list_title_text {
    display: flex;
    flex: 4;
    width: 60%;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
    .title_text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.accordian_list_title_icon {
    display: flex;
    width: 40%;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    .image-container {
        padding:  0 .5rem;
    }
}

.accordian_list_content {
    padding: .5rem 1rem;
    font-size: 1rem;
    animation: smoothDrop 300ms ease-out forwards;
    transform-origin: top center
}

.icon-btn{
    cursor: pointer;
    margin-right: 8px;
    color: $brand-primary-blue-1;
}

.cursor-pointer {
    cursor: pointer;
}

@keyframes smoothDrop {
    0% {
        transform: scaleY(0)
    }
    100% {
        transform: scaleY(1)
    }
}

.selected {
    background: $light-gray;
    font-weight: 700;
}

.disabled_action_icon  {
    &:hover {
        cursor: no-drop;
    }
}

.accordian_list_data{
    cursor: pointer;
    padding: 9px 0;
    display: flex;
    align-items: baseline;
    .list_text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
    }
}

.error_div {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
}

.new_user_indicator {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
}