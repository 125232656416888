.subscribed_products_description * {
	font-size: 16px !important;
}

.subscription_tier_wrapper {
	.subscription_tier_header {
		font-size: 16px;
		font-weight: 700;
	}

	.subscription_tier_name {
		font-size: 16px;
		font-weight: 400;
	}

	.included_products_wrapper {
		margin-top: 30px;
	}

	.included_products_title {
		font-size: 16px;
		font-weight: 700;
	}

	.included_products_list {
		margin-top: 1rem;

		.productListWrapper {
			input {
				margin-right: 1rem;
			}

			label {
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
	.included_products_wrapper {
		margin-top: 30px;
	}

	.included_products_title {
		font-size: 16px;
		font-weight: 700;
	}

	.included_products_list {
		margin-top: 1rem;

		.productListWrapper {
			input {
				margin-right: 1rem;
			}

			label {
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
	.included_products_wrapper {
		margin-top: 30px;
	}

	.included_products_title {
		font-size: 16px;
		font-weight: 700;
	}

	.included_products_list {
		margin-top: 1rem;

		.productListWrapper {
			input {
				margin-right: 1rem;
			}

			label {
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
}
.included_products_wrapper {
	margin-top: 30px;
}

.included_products_title {
	font-size: 16px;
	font-weight: 700;
}

.included_products_list {
	margin-top: 1rem;
	cursor: default;

	.productListWrapper {
		input {
			margin-right: 1rem;
		}
		.checkboxDisabled {
			opacity: 0.6;
			&:hover {
				cursor: not-allowed;
			}
		}
		label {
			font-size: 16px;
			font-weight: 400;
		}
		.subscribe-disabled-label {
			cursor: not-allowed;
		}
	}
}

.action_button_wrapper {
	.action_button {
		margin: 1rem 0;
		background-color: #0973ba;
		color: #fff;
	}
}

.checkboxDisabled {
	opacity: 0.6;
	&:hover {
		cursor: default;
	}
}
