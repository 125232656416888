.action_button_wrapper {
    .action_button {
        margin: 1rem 0;
        background-color: #0973BA;
        color: #fff;
    }
}

.connectionstatus {
    font-size: 14px;
}

.connectionstatus span {
    vertical-align: middle;
    font-style: italic;
} 

.connected {
    font-weight: bold;
    font-style: italic;
}

.disconnect {
    background: #F0F1F1;
    border: 1px solid #898D91;
    border-radius: 2px;
    color: #202223;
    font-size: 1rem;
    margin: 1rem 0;

    &:hover,
	&:focus,
	&:active {
		background: #F0F1F1 !important;
        color: #202223 !important;
	}
}
 