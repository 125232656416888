@import 'src/styles/variables';

.clientsListTable {
    table-layout: fixed;
    color: $primary-font-color;
    font-size: max(min(2.1vw, 1rem), 12px);
    line-height: 1rem;
    border-spacing: 0px;
    margin-bottom: 10px;

    thead {
        border-bottom: 2px solid $primary-font-color;

        th {
            padding: 4px 4px 5px;
            font-weight: 700;
        }

        th.widthAuto {
            width: 100%;
        }

        th.actions {
            width: 120px;
        }
    }

    tbody {
        .clientsLoadingArea {
            vertical-align: middle;
            color: $primary-blue !important;
            cursor: default;

            .spinner-border {
                border-right-color: lightgray;
                border-bottom-color: lightgray;
                border-left-color: lightgray;
            }

            .clientsLoadingText {
                color: rgb(136, 136, 136);
                margin-left: 6px;
            }
        }

        tr.clientRow {
            position: relative;

            .clientIdColumn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                height: 24px;

                .leftSideName {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    height: 18px;
                    margin-top: 2px;
                    min-width: 50px;

                    svg {
                        position: absolute;
                        left: -5px;
                        top: 5px;
                    }
                }

                .icons {
                    display: flex;
                    align-items: center;
                    max-width: 95px;
                }

                .width30 {
                    max-width: calc(100% - 30px);
                }

                .width70 {
                    max-width: calc(100% - 70px);
                }

                .deceasedIcon {
                    width: 66px;
                    height: 20px;
                    font-size: 14px;
                }

                .mfjIcon {
                    width: 16px;
                    height: 20px;
                    font-size: 14px;
                    margin-left: 10px;

                    svg {
                        width: 14px;
                        height: 16px;
                    }
                }
            }

            td {
                padding: 4px 4px 3px;
                vertical-align: middle;
            }

            @media (max-width: 500px) {
                td {
                    padding: 3px 2px 0px 3px;
                    vertical-align: middle;
                }
            }

            td.widthAuto {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            td.clientIdTableData {
                padding-right: 24px;
            }

            td.actionsColData {
                font-size: max(min(1.9vw, 1rem), 12px);
                padding: 4px 12px 3px 4px;

                .dropdown-toggle::after {
                    display: none !important;
                }

                .alignActionButtons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                button {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    color: $brand-primary-blue-1;
                    font-weight: 400;
                }

                button.editclientButton {
                    padding: 0;
                }

                button.deleteclientButton {
                    padding: 0;
                }

                .clientMoreActions button {
                    min-width: 0px;
                }

                .divider svg {
                    width: max(min(1.3vw, 1rem), 6px);
                }

                .dropdown svg {
                    width: max(min(1.6vw, 1rem), 9px);
                }
            }
        }
    }

    .clickableColumns {
        cursor: pointer;
    }
    
    .sortIcon {
        color: #6B7075;
        font-size: 11px;
        line-height: inherit;
    }
    
    .column {
        display: flex;
        align-items: center;
    
        &__name {
            width: auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: normal;
        }
    }
    
    .self-end {
        align-self: self-end;
    }
}
