@import 'src/styles/variables';

.saveUserGroupModal .modal-dialog {
    display: flex;
    width: 500px;
    max-height: calc(100% - 3.5rem);

    .modal-content {
        border-radius: 4.8px;
        box-sizing: border-box;
        border: none;
        background-color: transparent;

        .modal-header {
            background: $brand-primary-blue-1;
            border-bottom: 1px solid #DEE2E6;
            line-height: 24px;
            font-size: 20px;
            padding: 9px 1rem;
            border-top-left-radius: 4.8px;
            border-top-right-radius: 4.8px;

            .closeIcon {
                color: #99bde1;
                width: 26px;
                line-height: 24px;
                cursor: pointer;
            }

            .modal-title {
                line-height: 24px;
                font-size: 20px;
                color: #FFFFFF;
            }
        }

        .modal-body {
            background: #fff;
            overflow-y: auto;

            .container {
                padding: 0;

                .row {
                    position: relative;
                }

                input[type="text"],
                input[type="email"] {
                    height: 31px;
                    font-size: 14px;
                }

                .alignMFACol {
                    display: flex;
                    align-items: flex-start;
                    margin-top: 2rem;

                    .userEnableMFA {
                        margin-right: 0.5rem;
                    }

                    label {
                        margin-top: -4px;
                        font-size: 14px;

                        svg {
                            margin-top: -5px;
                        }
                    }
                }

                .personalInformationText {
                    font-size: 15px;
                    font-weight: 700;
                    color: $primary-font-color;
                }

                .form-label {
                    margin-bottom: 0.25rem;
                    font-size: 14px;
                }

                .mobileSection {
                    label {
                        font-size: 14px;
                        margin-bottom: 0.25rem;
                    }

                    .inputCCMobile {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                        box-sizing: border-box;
                        border: 1px solid $input-border-color;
                        border-radius: 2px;
                        height: 31px;
                        box-sizing: border-box;

                        .userCountryCode {
                            font-size: 14px;
                            border: none;
                            width: 35%;
                        }

                        .userMobile {
                            border: none;
                            border-left: 1px solid $input-border-color;
                            width: 65%;
                            height: 29px;
                            padding: 0.25rem 0.5rem;
                        }
                    }
                }

                .isOptional {
                    display: flex;
                    justify-content: space-between;
                }

                .form-control {
                    background: #FFFFFF;
                    border: 1px solid $input-border-color;
                    border-radius: 2px;
                    padding: 0.25rem 0.5rem;
                }

                .saveUserFieldErrorBorder {
                    border: 1px solid $primary-error !important;
                }

                .saveUserFieldErrorMessage {
                    color: $primary-error;
                    padding: 4px 0 0;
                    display: block;
                    font-size: 14px;
                }

                .noEmailMatch {
                    display: none;
                    position: absolute;
                    top: 26px;
                    right: 13px;
                    background-color: white;
                    height: 29px;
                    width: 23px;
                    text-align: center;
                    padding-top: 3px;
                }

                .showError {
                    display: block;
                }

                .ptinInfo svg {
                    margin-top: -5px;
                }

                .ugTags {
                    box-sizing: border-box;
                    border: 1px solid $input-border-color;
                    border-radius: 2px;
                    padding: 2px 2.5px;
                    display: flex;
                    justify-content: flex-start;
                    overflow-x: hidden;
                    overflow-y: auto;
                    flex-wrap: wrap;
                    font-size: 14px;
                    scrollbar-width: thin;
                    max-height: 4.5rem;
                    min-height: 2.2rem;

                    .eachUGTag {
                        margin: 2px 2.5px;
                        border-radius: 4px;
                        padding: 1px 8px;
                        white-space: nowrap;
                        height: 26px;

                        .tagDeselect {
                            margin-left: 2px;
                            cursor: pointer;

                            svg {
                                width: 15px;
                            }
                        }
                    }
                }

                .ugTags::-webkit-scrollbar:vertical {
                    width: 0.4rem;
                }

                .searchUserRoleDiv {
                    border: 1px solid $input-border-color;
                    display: flex;
                    align-items: center;
                    padding-right: 8px;
                    border-radius: 2px 2px 0px 0px;

                    .searchUserRole {
                        border: none;
                        box-shadow: none;
                        display: inline-block;
                        width: 96%;
                    }
                }

                .ugCheckArea {
                    border: 1px solid #898D91;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
                    border-radius: 0px 0px 2px 2px;
                    padding: 6px 12px;
                    height: 7rem;
                    overflow-y: auto;
                    scrollbar-width: thin;

                    .eachUGCheck {
                        list-style: none;
                        display: flex;
                        align-items: center;
                        height: 1.5rem;
                        font-size: 14px;

                        .ugCheckbox {
                            margin-right: 10px;
                        }

                        label {
                            color: $primary-font-color;
                            font-size: 14px;
                        }
                    }
                }

                .ugCheckArea::-webkit-scrollbar:vertical {
                    width: 0.4rem;
                }
            }
        }

        .modal-footer {
            background: #fff;

            .btn-secondary {
                background: #F0F1F1;
                border: 1px solid $input-border-color;
                border-radius: 2px;
                color: #202223;
                font-size: 1rem;
            }

            .btn-primary {
                background: $primary-blue;
                border: 1px solid $primary-blue;
                border-radius: 2px;
                color: #FFFFFF;
                font-size: 1rem;
            }
        }
    }
}