@import 'src/styles/variables';

.breadCrumbNav {
    .breadcrumb {
        margin-bottom: 1rem;
        padding: 0px;
        display: flex;

        .breadcrumbItem {
            list-style: none;

            a {
                text-decoration: none;
                color: $primary-blue;
                font-weight: 700;
            }
        }

        .activePage {
            color: $primary-font-color;
        }

        svg {
            margin-top: -2px;
            margin-left: -1px;
            margin-right: -1px;
        }
    }
}