@import 'src/styles/variables';

.asterisk {
    color: $primary-error;
}

.event_modal label{
    font-size: $font-size-md;
    padding: 0 2px 4px 2px;
} 

.add_email{
    background-color: #0973BA;
    color: $white;
    padding: 7px 10px;
    font-size: 16px;
}


.event_modal .add_email {
    min-width: 0;
}

.close_icon {
    color: #FFF;
    width: 26px;
    line-height: 24px;
    cursor: pointer;
}

.trash_icon {
    cursor: pointer;
    color: $brand-primary-blue-2;
}