.add_company_card_container {
    border: 2px dashed #05386B;
    border-radius: 4px;
    width: auto;
    min-width: 250px;
    height: 200px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .add_company_icon {
        margin-bottom: 24px;
    }

    .add_company_text {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #212529;
    }
}