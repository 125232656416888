@import 'src/styles/variables';

.usageReportHeader {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 31px;
	flex-wrap: wrap;

	.column1 {
		display: flex;

		.commonLabel {
			font-weight: 600;
			font-size: 14px;
			line-height: 150%;
			margin-bottom: 3px;
		}

		.productTypeDropdown {
			width: 180px;
			margin-right: 22px;
		}

		.returnTypeDropdown {
			width: 175px;
			margin-right: 22px;
		}

		#OfficeLocation {
			width: 210px;
			margin-right: 22px;
		}

		.dateDropdown {
			width: 145px;
			margin-right: 22px;
		}

		.commonDatepicker {
			font-size: 14px;
			width: 98px;
			margin-right: 22px;
		}
	}

	.column2 {
		display: flex;
		margin-top: 20px;

		.printIcon {
			margin-right: 10px;
		}

		.printIcon,
		.exportIcon {
			border: 1px solid #6b7075;
			background-color: rgba(255, 255, 255, 1e-6);
			padding: 10px;
			height: 38px;
			width: 38px;
			color: $primary-font-color;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		.runReportBtn {
			width: 126px;
			height: 38px;
			padding: 6px 12px;
			background: $brand-primary-blue-2;
			border: 1px solid $brand-primary-blue-2;
			border-radius: 2px;
			margin-right: 10px;

			font-size: 16px;
			font-weight: 400;
			line-height: 150%;
			color: $white;
		}
	}

	.react-datepicker__input-container {
		input {
			width: 120px;
			height: 38px;
			padding-left: 9px;
			border: 1px solid #898d91;
			border-radius: 2px;
			outline-style: none;
		}
	}
}

.no-drop {
	cursor: no-drop;
}
