@import 'src/styles/variables';

.suiteModal {
    .ssoModalBody {
        padding: 1rem 0;
        color: #212529;
        font-family: 'Mulish';
        font-style: normal;

        .nav-tabs {
            border-bottom: 1px solid #C4C6C8;
            color: #212529;

            li {
                width: 50%;
                text-align: center;

                .nav-link {
                    border: none;
                    width: 100%;
                    border: 0;
                    color: #212529;
                    font-weight: 400;
                    font-size: 16px;
                }

                .nav-link:hover {
                    border: 0;
                }

                .nav-link.active {
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    color: #0973BA;
                    border-bottom: 3px solid #0973BA;
                    font-weight: 700;
                    font-size: 1rem;
                }
            }

        }

        .tab-content {
            padding: 1.5rem 1rem;
        }
    }

    .ssoModalFooter {
        background: #fff;

        .btn-secondary {
            background: #F0F1F1;
            border: 1px solid $input-border-color;
            border-radius: 2px;
            color: #202223;
            font-size: 1rem;
        }

        .btn-primary {
            background: $primary-blue;
            border: 1px solid $primary-blue;
            border-radius: 2px;
            color: #FFFFFF;
            font-size: 1rem;
        }

        .button-hide {
            display: none;
        }
    }
}