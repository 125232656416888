@import '../../../styles/variables';

.table_container {
    margin-top: 1.5rem;

    :global .table-bordered {
        border: 1px solid #C4C6C8;
        thead {
            &:first-child {
                th:not([class]) {
                    border-right:1px solid transparent;
                }
            }
            &:last-child {
                border-bottom: 1px solid #898D91;
            }
        }
    }

    :global .table-striped>tbody>tr:nth-of-type(even)>* {
        --bs-table-accent-bg: var(--bs-table-striped-bg);
        color: var(--bs-table-striped-color);
    }
    :global .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-accent-bg: $white;
        color: var(--bs-table-striped-color);
    }
   .actionSectionBorder{
    border-right: 1px solid #C4C6C8;
   }

    .input {
        padding: .5rem;
        width: 100%;
        border: 1px solid #898D91;
        border-radius: 5px;
    }

    .searchInput {
        @extend .input;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23898D91' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
        background-size: 16px 16px;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-position: right;
    }

    .addIpSection {
        background-color: #F0F1F1;
        border-right: 1px solid #C4C6C8;
        th:nth-last-child(2){
            border-right: 1px solid #C4C6C8 !important;
        }
    }
}

.actionSection {
    vertical-align: middle;
    min-width: 8rem;
    font-size: 16px;
    padding-left: 12px !important;
font-weight: 400;
}

.actionDivButtonSection {
    min-width: 15rem;
        max-width: 15rem;
            width: 15rem;
            border-left: 1px solid #C4C6C8;
}

.addButtonSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    border: 1px solid #898D91;
    border-radius: 2px;
    padding: .2rem 1.5rem;
    font-size: 14px;
    background-color: #fff;
    color: #212529;
        width: 100px;
        height: 31px;
        justify-content: center
}

.addButton {
    @extend .button;
    svg {
        height: 16px;
        width: 16px;
        margin-right: .5rem;
        color: #05386B; 
       
    }
}

.cancleButton {
    @extend .button;
}

.saveButton {
    @extend .button;
    border: 1px solid #0973BA;
    color: #fff;
    background-color: #0973BA;
}

.deleteButton {
    @extend .button;
    padding: .2rem 1.1rem;
    svg {
        height: 16px;
        width: 16px;
        margin-right: .5rem;
        color: #CC4A43;
    }
}

.actionDivButton {
    display: flex;
        align-items: center;
            justify-content: center;
            height: 35px
}

.textStyle {
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle;
}

.noRecordDiv {
    font-size: 14px;
    td {
        box-shadow: none;
        border: none;
        border-width: 0;
    }
    span {
        font-style: italic;
    }
}

:global .disabled_button {
    opacity: .65;
    color: #000;
}

.navigatorContainer {
    display: flex;
    align-items: center;
    border-radius: 4px;
    .navAction {
            cursor: pointer;
            margin-right: 1px;
            border: 1px solid #EAECEF
        }
    .pageNumbers {
        height: 38px;
            width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1rem;
        margin-right: 1px;
        background-color: #0973BA;
        border: 1px solid #0973BA;
        cursor: default;
    }
    .disabled_nav {
        cursor: not-allowed;
        background-color: #EAECEF;
        svg {
            color: #abadaf;
        }
    }
    svg {
        color: #0973BA;
        margin: 10px;
    }

}

:global input[type=checkbox][aria-disabled="true"] {
    background-color: #e9ecef;
    cursor: not-allowed;

    &:active {
        pointer-events: none;
    }
}