
.unauthorized{
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    h2{
        font-size: 2rem;
        margin-bottom: .9rem;
        line-height: 1.2;
        font-weight: 500;
    }

    .unauthorized-message{
        font-size: 1rem;

    }

}

