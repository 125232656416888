.oneHubUrlSection {
	margin-top: 2rem;

	.header {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		color: #212529;
	}

	.urlContainer {
		margin-top: 0.5rem;
		display: flex;
		align-items: center;
		gap: 1rem;

		.inputContainer {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border: 1px solid #898d91;
			border-radius: 3.2px;
			height: 31px;
			overflow: hidden;

			.https {
				width: 64px;
				height: 29px;
				background-color: #f0f1f1;
				font-family: Mulish;
				font-size: 14px;
				font-weight: 500;
				line-height: 16.8px;
				color: #212529;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.urlInput {
				width: 300px;
				height: 29px;
				background-color: #fff;
				font-family: Mulish;
				font-size: 14px;
				font-weight: 500;
				line-height: 16.8px;
				color: #212529;
				border-left: 1px solid #898d91;
				border-right: 1px solid #898d91;
				border-top: 0px;
				border-bottom: 0px;
				padding: 4px 8px 5px 8px;
			}

			.urlInput:disabled {
				cursor: not-allowed !important;
			}

			.errorBorder2 {
				border-left: 1px solid #cc4a43 !important;
				border-right: 1px solid #cc4a43 !important;
			}

			.domain {
				width: 120px;
				height: 29px;
				background-color: #f0f1f1;
				font-family: Mulish;
				font-size: 14px;
				font-weight: 500;
				line-height: 16.8px;
				color: #212529;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.errorBorder1 {
			border: 1px solid #cc4a43 !important;
		}

		.buttonContainer {
			.editContainer {
				.editButton {
					width: 100px;
					height: 31px;
					padding: 4px 8px;
					display: flex;
					gap: 8px;
					align-items: center;
					justify-content: center;
					border-radius: 2px;
					border: 1px solid #0973ba;
					font-family: Mulish;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					color: #0973ba;
					cursor: pointer;
				}
			}

			.updateContainer {
				display: flex;
				align-items: center;

				.updateButton {
					width: 80px;
					height: 31px;
					padding: 4px 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 2px;
					border: 1px solid #0973ba;
					font-family: Mulish;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					background-color: #0973ba;
					color: #fff;
					cursor: pointer;
				}

				.updateButton:disabled {
					opacity: 0.5;
					cursor: not-allowed;
				}

				.cancelButton {
					margin-left: 1rem;
					width: 80px;
					height: 31px;
					padding: 4px 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 2px;
					border: 1px solid #0973ba;
					font-family: Mulish;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					color: #0973ba;
					cursor: pointer;
				}
			}
		}
	}

	.urlError {
		margin-top: 0.6rem;
		color: #cc4a43;
		font-family: Mulish;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
	}

	.urlNamingCriteria {
		margin-top: 1.2rem;

		h5 {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 700;
			line-height: 21px;
			color: #6b7075;
			text-decoration: underline;
		}

		ul {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			color: #6b7075;
			padding-left: 1.5rem;
			margin-bottom: 0px;
		}
	}
}
