.ipFilterSection {
    display: flex;
    align-items: end;
}

.clientIpText {
    font-size: 16px;
    font-weight: 400;
    margin-left: 8rem;
}

.switchSettingsBlock {
    #ipAddressOptionCheck:focus {
        outline: none;
        box-shadow: none;
    }
}