.trustedIPHeader {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.9rem;
}

.skipMFACheck {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    input {
        margin-right: 0.5rem;
        cursor: pointer;
    }

    label {
        font-weight: 400;
        font-size: 1rem;
        white-space: nowrap;
        cursor: pointer;
    }
}

.skipMFAIPHead {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.reauthenticateDaysBlock {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;

    input {
        width: 2rem;
        margin-left: 0.5rem;
        padding: 0 3px;
    }
}