.fromIPCol,
.toIPCol {
    width: 30%;
    vertical-align: middle;
    padding: 0.4rem 0.5rem;

    input {
        background: #FFFFFF;
        padding: 4px 8px;
        border: 1px solid #898D91;
        border-radius: 3.2px;
        width: 150px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #212529;
    }
}

.actionsCol {
    width: 60%;
    text-align: center;
    vertical-align: middle;
    padding: 0.4rem 0.5rem;

    .addIPCol {
        text-align: center;

        button {
            width: 100px;
            background: #FFFFFF;
            border: 1px solid #898D91;
            border-radius: 2px;
            font-weight: 400;
            font-size: 14px;
            color: #212529;
            padding: 4px 8px;

            svg {
                height: 16px;
                width: 16px;
                margin-top: -2px;
                margin-right: 6px;
            }
        }
    }
}

.dispFlex {
    display: flex;
    justify-content: space-between;

    button {
        width: 100px;
        border-radius: 2px !important;
        font-weight: 400 !important;
        font-size: 14px;
        padding: 4px 8px !important;
    }
}

.editIPCell {
    input {
        background: #FFFFFF;
        padding: 4px 8px;
        border: 1px solid #898D91;
        border-radius: 3.2px;
        width: 150px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #212529;
    }
}

.cellValueIP {
    font-weight: 400;
    font-size: 14px;
    vertical-align: middle;
}

.deleteIPCell {
    text-align: center;

    button {
        background: #FFFFFF;
        border: 1px solid #898D91;
        border-radius: 2px !important;
        padding: 4px 8px !important;
        width: 100px;
        font-size: 14px;
        font-weight: 400 !important;
        color: #212529;

        svg {
            height: 16px;
            width: 16px;
            margin-top: -2px;
            margin-right: 6px;
        }
    }

    button:hover {
        background: #FFFFFF;
        border: 1px solid #898D91;
    }
}

.noData {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
}

.confirmationPopup .modal-dialog {
    display: flex;
    box-shadow: 5px 5px 61px 0px;
}