@import 'src/styles/variables';

.content {
    width: 100%;
    height: 100%;
}

.clear {
    clear:both;
}

.developer_section {
    padding: 10px 20px 20px 20px;
    color: $primary-font-color;
    font-size: 14px;
    height: calc(100vh - 85px);
    overflow: hidden;
    overflow-y: scroll;
}

.developer_section_heading {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
}

.table_header {
    color : $primary-blue;
    font-size: $heading-size-1;
    float: left;
}