.clientManagement {
    padding-top: 1rem;

    div {
        padding-bottom: 3px;

        label {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            margin-left: 0.5rem;
            color: #151617;
        }

        span {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin-left: 1.3rem;
            width: 360px;
            color: #565A5E;
        }
    }
}

.hideDiv {
    display: none;
    visibility: hidden;
}
.showDiv {
    display: block;
    visibility: visible;
}
