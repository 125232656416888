@import '../../../../../styles/variables';

.column-with-header-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	width: 100%;
	.column-header {
		font-size: 0.75rem;
		margin: 1rem 0;
		font-weight: bold;
	}
}

.column-container {
	border-right: 0.1rem solid $gray;
	overflow: scroll;
	height: 100%;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none;
	}
}

.element-container {
	font-size: 1rem;
	padding: 0.8rem 1rem;
	cursor: pointer;
	display: flex;

	.image-element {
		align-self: flex-end;
	}

	.text-element {
		flex: 1;
		align-self: flex-start;
		display: flex;
		width: 95%;
		align-items: baseline;
		.text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 90%;
		}
	}
}

.selected-element {
	border-left: 0.25rem solid $primary-blue;
	background-color: $bg-grey;
	font-weight: bold;
}

.disabled-element {
	cursor: not-allowed;
	background-color: $brand-secondary-gray;
}
