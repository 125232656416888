.ssrDefault {
    pointer-events: none;
    color: #2125294D !important;
    
   select{
    border:1px solid #898D914D !important;
   }
   label{
    color:#1516174D !important;
   }
   p{
    color:#565A5E4D !important;
   }
}

.disabledTextColor {
    color: #C4C6C8 !important;
}

.passwordOptions {
    padding-top: 1rem;

    div {
        display: flex;
        align-items: center;
        padding-bottom: 3px;

        input {}

        label {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            margin-left: 0.5rem;
            color: #151617;
        }
    }
}

.pwdLength {
    padding-top: 1rem;
    padding-bottom: 0.3rem;

    select {
        padding: 4px 12px 4px 8px;
        width: 60px;
        border: 1px solid #898D91;
        border-radius: 2px;
        cursor: pointer;
        color: #212529;
    }
}

.passwordAgeText {
    padding-top: 0.5rem;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;

    .requireText {}

    .selectBox {
        padding: 0 0.5rem;

        select {
            padding: 4px 12px 4px 8px;
            border: 1px solid #898D91;
            border-radius: 2px;
            font-weight: 400;
            font-size: 14px;
            cursor: pointer;
            color: #212529;
        }
    }

    .monthsText {
        label {
            font-weight: 400;
            font-size: 14px;
            color: #dc3545;
        }
    }
}