.clientHubTooltip {
	.tooltip-inner {
		max-width: 360px;
		text-align: left;
		padding: 4px 8px;
		border-radius: 4px;
		font-family: 'Mulish', sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
	}
}

.editContactPersonButton {
	align-items: center;
	border: 1px solid #0973ba;
	background-color: #fff;
	border-radius: 2px;
	color: #0973ba;
	cursor: pointer;
	display: flex;
	height: 31px;
	justify-content: center;
	padding: 4px 8px;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin-top: 24px;

	&:hover,
	&:focus,
	&:active {
		background-color: #fff !important;
		color: #0973ba !important;
	}
}
