@import 'src/styles/variables';

.tabHeader {
    display: flex;
    border-bottom: 1px solid $gray;
    padding: 0.8rem 0 0 0;
    margin-bottom: 0.3rem;
    font-size: 1rem;
}

.tabHeader .tabSelected {
    color: $primary-blue;
    border-bottom: 3px solid $primary-blue;
    font-weight: 700;
    cursor: default;
}

.tabHeader li {
    list-style: none;
    padding: 0.5rem 1rem;
    margin-bottom: -1px;
    font-weight: 400;
    cursor: pointer;
}