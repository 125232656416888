@import '../../../styles/variables';

.button {
    margin: 0 .5rem;
    padding: .5rem 1rem;
    transition: .4s;
    display: flex;
    align-items: center;
    font-size: 1rem;
    border: 1px solid transparent;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.000001);

}

.button_border {
    border: 1px solid #6B7075;
}

.button_icon {
    margin: 0 0.3rem 0rem;
    height: 1rem;
}

.disabled_button {
    box-shadow: none !important;
    background-color: $gray;
    &:hover {
        cursor: no-drop;
    }
}