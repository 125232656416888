@import 'src/styles/variables';

.suiteModal {
    .modal-body {
        background: #fff;
        overflow-y: auto;

        .container {
            padding: 0;

            .infoText {
                font-size: 14px;
                font-weight: 700;
                color: $primary-font-color;
                margin: 0 0 5px 0;
            }

            .addOptions {
                font-size: 1rem;
                padding: 0;
                margin: 0;

                li {
                    display: flex;
                    align-items: center;
                    list-style: none;

                    input {
                        margin-right: 0.5rem;
                        margin-top: 1px;
                    }

                    label {
                        font-size: 1rem;
                    }

                    span {
                        display: flex;
                        margin-left: 6px;
                        margin-top: 2px;

                        svg {
                            cursor: pointer;
                        }
                    }
                }
            }

            .azureContainer {
                padding: 1rem 0 0 0;

                .listBlock {
                    display: flex;
                    align-items: center;
                    border: 1px solid rgba(0, 0, 0, 0.175);
                    border-radius: 4px;
                    width: 96%;
                    min-height: 77px;
                    padding: 1rem;

                    .list {
                        padding: 0;
                        margin: 0;

                        .ssCustomTag {
                            .react-tagsinput {
                                span {
                                    display: flex;
                                    flex-direction: column;

                                    .react-tagsinput-tag {
                                        background: #F0F1F1;
                                        border: 1px solid $primary-font-color;
                                        border-radius: 4px;
                                        padding: 1px 8px;
                                        width: fit-content;
                                        display: flex;
                                        align-items: center;
                                        flex-direction: revert;
                                        margin-bottom: 0.5rem;

                                        .react-tagsinput-remove {
                                            background-image: url('../../../../components/svg/icon-close.png');
                                            width: 10px;
                                            height: 10px;
                                            display: inline-block;
                                            cursor: pointer;
                                            margin-left: 6px;
                                        }
                                    }

                                    .react-tagsinput-input {
                                        width: 355px;
                                        font-size: 14px;
                                        border: 1px dashed #898D91;
                                        border-radius: 4px;
                                        padding: 1px 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    .modal-footer {
        background: #fff;

        .btn-secondary {
            background: #F0F1F1;
            border: 1px solid $input-border-color;
            border-radius: 2px;
            color: #202223;
            font-size: 1rem;
        }

        .btn-primary {
            background: $primary-blue;
            border: 1px solid $primary-blue;
            border-radius: 2px;
            color: #FFFFFF;
            font-size: 1rem;
        }
    }
}