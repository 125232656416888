.overlay-loader {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.overlay-loader::after {
    content: '';
    display: block;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: #0973BA;
    border-top-color: #F5F5F5;
    border-width: 2px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
    position: absolute;
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.35em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid #0973BA;
    border-right-color: lightgray;
    border-bottom-color: lightgray;
    border-left-color: lightgray;
}

.overlay-loader-text {
    text-align: center;
    font-size: 110%;
    color: rgb(136, 136, 136);
    position: relative;
    top: 47%;
    left: 8px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}