.color_display_wrapper {
    display: grid;
    grid: 1 1;
}

.header_design_action {
    .header_design_action_buttons {
        display: flex;
        color: #0973BA;
        border: 1px solid #0973BA;
        padding: 6.5px 24px;
        font-size: 14px;
        margin: 0px 1rem 0px 0px;

        svg {
            margin-right: 8px;
        }
    }
}

.header_design_description {
    margin-top: 1rem;
}