@import 'src/styles/variables';

.resetPasswordModal {
    display: flex !important;
    align-items: center;
    height: 100%;
    top: 0vh;

    .modal-dialog {
        display: flex;
        width: min(500px, 95vw);
        margin: 1.75rem auto;

        .modal-content {
            border-radius: 4.8px;
            box-sizing: border-box;
            border: none;
            background-color: transparent;

            .modal-header {
                background: #FFF;
                border-bottom: 1px solid #DEE2E6;
                line-height: 24px;
                font-size: 20px;
                padding: 1rem;
                border-top-left-radius: 4.8px;
                border-top-right-radius: 4.8px;

                .closeIcon {
                    color: rgba(0, 0, 0, 0.5);
                    width: 26px;
                    line-height: 24px;
                    cursor: pointer;
                }

                .modal-title {
                    color: $primary-font-color;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 120%;
                }
            }

            .modal-body {
                background: #fff;
                overflow-y: auto;

                .container {
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    color: $primary-font-color;
                    padding: 0;
                    line-height: 150%;
                    margin-bottom: 1rem;
                }
            }

            .modal-footer {
                background: #fff;

                .btn-primary {
                    background: $primary-blue;
                    border: 1px solid $primary-blue;
                    border-radius: 2px;
                    width: 83px;
                    color: #FFFFFF;
                    font-size: 1rem;
                }
            }
        }
    }
}