.action_button_wrapper {
    .action_button {
        margin: 1rem 0;
        background-color: #0973BA;
        color: #fff;
    }
}

.connectionstatus {
    font-size: 14px;
}

.connectionstatus span {
    vertical-align: middle;
    font-style: italic;
}

.connected {
    font-weight: bold;
    font-style: italic;
}

.disconnect {
    background: #F0F1F1;
    border: 1px solid #898D91;
    border-radius: 2px;
    color: #202223;
    font-size: 1rem;
    margin: 1rem 0;

    &:hover,
    &:focus,
    &:active {
        background: #F0F1F1 !important;
        color: #202223 !important;
    }
}

.status_container {
    display: flex; 
    align-items: center; 
}

.status_indicator {
    display: flex;
    align-items: center; 
    margin-left: 20px; 

    .status_connected_dot {
        width: 10px; 
        height: 10px;
        background-color: #28a745; 
        border-radius: 50%; 
        margin-right: 5px; 
    }
    .status_disconnected_dot {
        width: 10px; 
        height: 10px;
        background-color: gray; 
        border-radius: 50%; 
        margin-right: 5px; 
    }

    .status_text {
        color: #333;
        font-style: normal;
    }
}

.settings{
    padding-left: 1rem;
}