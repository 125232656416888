@import '../../../../../styles/variables';

.groups_list_container {
    width: 100%;
    min-width: 33.33%;

}

.section_header {
    display: flex;
    align-items: center;
    font-size: .75rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.content_container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.content_container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .content_container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.search_container {
    display: flex;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $gray;
    box-shadow: 0 0 1px rgb(0,0,0,50%);
    position: sticky;
    top: 0;
    background: #fff;
    input {
        width: 100%;
        border: none;
    }

    input:focus {
        border: none;
    }
}