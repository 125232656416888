.approvePasswordDiv {
    width: 620px;
    height: 372px;
    padding: 60px 0;
    border-radius: 15px;
    border: 1px solid #D3D6D8;
    text-align: center;
    margin: 5rem auto;

    .approvePasswordHeading {
        font-family: Mulish;
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
    }

    .approvePasswordBody {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-top: 2rem;

        span {
            font-weight: 700;
        }
    }

    .approvePasswordFooter {
        padding-top: 2rem;

        button {
            width: 250px;
            height: 38px;
            padding: 6px 12px 6px 12px;
            border-radius: 2px;
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        .alreadyApproved {
            background: #F0F1F1;
            color: #C4C6C8;
        }

        .approved {
            background: #669440;
            border: 1px solid #669440;
            color: #FFFFFF;
        }

        .approveDisabled {
            background: #669440;
            border: 1px solid #669440;
            color: #FFFFFF;
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
