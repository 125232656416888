.updateURLConfirmation {
	background-color: #fff;
	border-bottom-left-radius: 4.8px;
	border-bottom-right-radius: 4.8px;

	:global .modal-body {
		p {
			font-family: Mulish;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #212529;

			span {
				font-weight: 700;
			}
		}
	}

	:global .modal-footer {
		button {
			width: 100px;
			height: 38px;
			padding: 6px 12px;
			border-radius: 2px;
			font-family: Mulish;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #fff;
			margin: 0px;
		}

		.noButton {
			border: 1px solid #cc4a43;
			background-color: #cc4a43;
		}

		.yesButton {
			border: 1px solid #5c8001;
			background-color: #5c8001;
			margin-left: 0.5rem;
		}
	}
}
