.stepperContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .stepGroup {
        position: relative;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;

        .stepNumber {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            border: 2px solid #A6A9AC;
            border-radius: 50%;
            color: #A6A9AC;
            font-weight: 700;
        }

        .stepTitle {
            position: absolute;
            top: 30px;
            left: -63px;
            width: 150px;
            text-align: center;
            color: #212529;
        }
    }

    .stepInProgress {
        .stepNumber {
            color: #0973BA;
            border: 2px solid #0973BA;
            font-weight: 700;
        }

        .stepTitle {
            font-weight: 700;
        }
    }

    .stepCompleted {
        .stepNumber {
            background-color: #0973BA;
            border: 2px solid #0973BA;
        }

        .stepTitle {
            font-weight: 400;
        }
    }

    .stepConnector {
        border: 1px solid #A6A9AC;
        width: 150px;
        height: 1px;
    }

    .blueConnector {
        border: 1px solid #0973BA;
    }
}