.groups_content_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-height: calc(100vh - 20rem);
    margin-top: 2.5rem;
}

.content_container {
    border: 1px solid #C4C6C8;
    border-radius: 3px;
}