@import 'src/styles/variables';

.updateContactPersonModal {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.updateContactPersonModal .modal-dialog {
    width: 550px;
    max-width: 550px;

    .modal-content {
        .modal-header {
            border-bottom: 1px solid #DEE2E6;
            line-height: 24px;
            font-size: 20px;
            padding: 9px 1rem;
            border-top-left-radius: 4.8px;
            border-top-right-radius: 4.8px;

            .closeIcon {
                color: #99bde1;
                width: 26px;
                line-height: 24px;
                cursor: pointer;
            }
        }

        .modal-body {
            margin: 20px 20px 0px 20px;
            padding-bottom: 0px !important;

            .column-1 {
                .title {
                    margin-bottom: 6px;
                    font-weight: 600;
                }
            }

            .headerText {
                font-size: 16px;
                margin-bottom: 20px;
            }

            .warningMessage {
                border: 1px solid #AB7F2D;
                background-color: #FBF5EB;
                padding: 16px;
                border-radius: 4px;
                display: flex;
                align-items: center;

                .icon {
                    width: 16px;
                    margin-right: 12px;
                }

                .text {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #564016;

                    .bold {
                        font-weight: 700;
                    }
                }
            }

            .saveUserFieldErrorMessage {
                color: $primary-error;
            }
        }

        .modal-footer {
            background: #fff;

            .btn-secondary {
                background: #F0F1F1;
                border: 1px solid $input-border-color;
                border-radius: 2px;
                color: #202223;
                font-size: 1rem;
            }

            .btn-primary {
                background: $primary-blue;
                border: 1px solid $primary-blue;
                border-radius: 2px;
                color: #FFFFFF;
                font-size: 1rem;
            }
        }
    }

    .rowContentDropdown {
        width: 225px;
    }
}

.flex-justify-center {
    display: flex;
    justify-content: center;
    align-items:center;
}
