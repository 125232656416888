//---- Global Modal Styling -----
.modal {

    .modal-header {
        background-color: $brand-primary-blue-1;
        color: $white;
        border-bottom: none;
        padding: 10px 16px;
        font-size: $heading-size-2;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        justify-content: space-between;

        .modal-title {
            color: $white;
            font-size: $heading-size-1;
            font-weight: 400;
        }
    }
    &-content {
        border-radius: 5px;
    }

    &-footer {
        font-size: $font-lg;
    }
}

.last-login {
    font-size: $font-lg;
    .modal-dialog {
        max-width: 500px;
    }

    .form-check label {
        font-size: $font-lg;
    }
}

.logout-confirmation {
    .modal-body {
        font-size: $font-lg;
    }
    .modal-footer {
        button.danger {
            background-color: #B8433C;
            border-color: #CC4A43;
        }
    }
}

.login-history {
    .modal-dialog {
        max-width: 815px;
    }
}

.my-account-modal {
    .modal-dialog {
        max-width: 933px;
        height: 90%;
    }
    .modal-content {
        height: 100%;
        max-height: 623px;
        overflow: hidden;
    }
    .modal-body {
        overflow: auto;
        height: calc(100% - 50px);
    }
}

//Custom Close Icon

.modal .modal-header {
    .btn-close {
        background-size: 17px;
        padding: 8px;
        height: 16px;
        width: 16px;
    }
}