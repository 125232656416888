@import '../../../../../styles/variables';

.accordian_description_list_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: scroll;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none;
	}
}
.accordian_description_list {
	border-bottom: 1px solid #ddd;
}

.accordian_description_list_title {
	font-size: 1rem;
	display: flex;
	width: 100%;
	padding: 0.5rem 1rem;
}
.accordian_description_list_title_text {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.accordian_description_list_title_icon {
	display: flex;
	width: 40%;
	justify-content: flex-end;
	align-items: center;
	font-size: 0.875rem;
	font-weight: bold;

	.image-container {
		padding: 0 0.5rem;
	}

	svg {
		margin-left: 0.5rem;
		color: $success-green;
	}
}

.accordian_description_list_content {
	padding: 1rem;
	font-size: 1rem;
	animation: smoothDrop 300ms ease-out forwards;
	transform-origin: top center;
	pointer-events: none;
}

@keyframes smoothDrop {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}

.selected {
	background: $bg-grey !important;
	font-weight: 700;
}

.accordian_description_list_subaction {
	display: grid;
	margin: 1rem 0;
	grid-template-columns: auto auto;
}

.subaction_list {
	display: flex;
	padding-right: 0.5rem;
	align-items: center;

	label {
		padding: 0 0.5rem;
		font-size: 1rem;
	}

	* {
		cursor: not-allowed;
		pointer-events: auto;
		opacity: 0.5;
	}
}

.accordian_description_list_text {
	font-size: 1rem;
}

.accordian_description_list_header {
	font-size: 0.75rem;
	margin: 1rem 0;
	font-weight: bold;
}
.accordian_description_list_dropdown_header {
	font-size: 1rem;
	margin: 1rem 0;
	font-weight: bold;
}
.subaction_list_tooltip {
	position: absolute;
	width: 289px;
	height: 65px;
	padding: 10px 13px;
	border-radius: 1px;
	text-align: left;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #202223;
	background-color: #f0f1f1;
	border: 1px solid #898d91;
}
