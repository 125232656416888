.button-default {
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    text-align: center;
    border: none;
    padding: 5px 20px;
    min-width: 100px;
    cursor: pointer;
    line-height: normal;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.button-primary-green {
    @extend .button-default;
    background-color: $brand-primary-green;
    color: $white;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
}

.button-primary-green-outlined {
    @extend .button-default;
    background-color: $white;
    border: 1px solid $brand-primary-green;
    color: $brand-primary-green;
}

.button-white {
    @extend .button-default;
    background-color: $white;
    border: 1px solid #e0e0e0;
    color: $brand-secondary-black;
    border-radius: 2px;
    min-width: 80px;
}

.button-primary-green-rounded {
    @extend .button-primary-green;
    border-radius: 50px;
    box-shadow: none;

    &.btn-shamrock {
        background-color: $shamrock;
        font-weight: 400;
        line-height: 24px;
        font-size: $font-lg;
    }
}

.button-primary-blue {
    @extend .button-default;
    color: $white;
    background-color: $brand-primary-blue-1;
}

.button-primary-blue-rounded {
    @extend .button-primary-blue;
    border-radius: 18px;
}

.button-primary-blue-2 {
    @extend .button-default;
    color: $white;
    background-color: $brand-primary-blue-2;
}

.button-primary-blue-2-rounded {
    @extend .button-primary-blue-2;
    border-radius: 18px;
}

.button-primary-blue-2-outlined {
    @extend .button-default;
    background-color: $white;
    border: 1px solid $brand-primary-blue-2;
    color: $brand-primary-blue-2;
}

.add-recipient {
    border-bottom: 1px dashed $brand-primary-blue-2;
    cursor: pointer;
    display: block;
    padding: 10px 5px;
    position: relative;
    text-align: center;
    text-decoration: none;
}

.button-secondary-blue-outlined {
    @extend .button-default;
    background-color: white;
    outline: 1px solid $brand-primary-blue-2;
    color: $brand-primary-blue-2;
    padding: 5px;
    min-width: unset;
    padding-inline: 1em;

    &:hover {
        color: white;
        background-color: $brand-primary-blue-2;
    }
}

.button-secondary-grey-outlined {
    @extend .button-default;
    background-color: white;
    outline: 1px solid $brand-secondary-black-50;
    color: $brand-secondary-black-50;
    padding: 5px;
    min-width: unset;
    padding-inline: 1em;

    &:hover {
        color: white;
        background-color: $brand-secondary-black-50;
    }
}

.button-secondary-blue-default {
    @extend .button-default;
    color: $white;
    background-color: $brand-primary-blue-2;
    padding: 5px;
    visibility: visible !important;
    min-width: unset;
    padding-inline: 1em;
}

.button-underlined {
    @extend .button-default;
    background-color: transparent;
    text-decoration: underline;
    color: $brand-secondary-black;
}

.last-login-modal {
    .btn-lg {
        padding: 6px 12px;
    }

    .btn-lg:hover {
        border-radius: 0px;
    }
}
.button-gray-rounded {
    @extend .button-default;
    border-radius: 50px;
    box-shadow: none;
    background-color: $gray;
    color: $black;
}

// TOGGLE BUTTON
.switch {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 16px;
	margin-bottom: 0;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $white;
	-webkit-transition: .4s;
	transition: .4s;
	border: 1px solid $brand-primary-blue-2;
	&:before {
		position: absolute;
		content: "";
		height: 12px;
		width: 12px;
		left: 1px;
		bottom: 1px;
		background-color: $brand-primary-blue-2;
		-webkit-transition: .4s;
		transition: .4s;
	}
}
input {
	&:checked+.slider {
		&::before {
			background-color: $white;
		}
		&:before {
			-webkit-transform: translateX(15px);
			-ms-transform: translateX(15px);
			transform: translateX(15px);
		}
	}
	&:focus+.slider {
		&::before {
			box-shadow: 0 0 1px $brand-primary-blue-2;
		}
	}
}

input {
	&:not(:checked)+.slider {
        border: 1px solid #00000026;
		&::before {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),linear-gradient(0deg, #FFFFFF, #FFFFFF);            
		}		        
	}	
}

.custom {
	input {
		&:checked+.slider {
			background-color: $brand-primary-blue-2;
		}
	}
}
.slider.round {
	border-radius: 34px;
	&:before {
		border-radius: 50%;
	}
}

.btn.btn-primary {
    background-color: $brand-primary-blue-2;
}

//Global default styles button
main, .modal {
    .btn {
        border-radius: 2px;
        font-weight: 300;
        padding: 6px 12px;
        min-width: 77px;
        &.btn-outline-secondary {
            background-color: #F0F1F1;
            color: $black;
        }
    }
}