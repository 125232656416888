@import 'src/styles/variables';

.table_header {
    color : $primary-blue;
    font-size: $heading-size-1;
}

.label {
    font-size: $font-lg;
    font-weight: bold;
    padding-left: 2px;
    margin-bottom: 5px;
}

.copy_key {
	float: right;    
	background-color: transparent;
	border: none;
    margin-top: -30px;
    margin-right: 12px;
    position: relative;
    z-index: 10;
    color : $brand-primary-blue-1;
}
