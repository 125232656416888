.purchaseModalClass {
    .purchaseTabs {
        li {
            display: flex;
            justify-content: center;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid rgba(255, 255, 255, 1e-06);
            height: 44px;
            font-weight: 400;
            font-size: 1rem;
            color: #212529;
            cursor: default;
            width: 50%;
            margin-bottom: -1px;
        }

        .purchase-tab-selected {
            font-weight: 700;
            color: #0973BA;
            border-bottom: 3px solid #0973BA;
            cursor: default !important;
        }
    }

    .purchaseModalFooter {
        button {
            width: 100px;
            height: 38px;
        }
    }
}