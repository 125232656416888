.blueGroup {
    background: #EBEFF9;
    border: 1px solid #355ABF;
    color: #2A4899;
}

.orangeGroup {
    background: #FBF0E7;
    border: 1px solid #A9570F;
    color: #A9570F;
}

.purpleGroup {
    background: #EEEAF3;
    border: 1px solid #43246C;
    color: #43246C;
}

.greenGroup {
    background: #E9F3ED;
    border: 1px solid #196C39;
    color: #196C39;
}

.yellowGroup {
    background: #fbfbe7;
    border: 1px solid #c9c919;
    color: #c9c919;
}

.defaultGroup {
    background: #fbe7e7;
    border: 1px solid #d31d1d;
    color: #d31d1d;
}

.popover-body {
    padding: 1rem 1rem 0 0;
}

.userDeleteConfirmationPopup{
    .modal-dialog {
        max-width: 542px;
    }

    .modal-body {
        font-size: 16px !important;
    }
}

.userDeleteAlterPopup{
    .modal-body {
        font-size: 16px !important;
    }
}