@import 'src/styles/variables';

.uteContactPersonModal .modal-dialog {
	display: flex;
	max-height: calc(100% - 3.5rem);
	width: min(500px, 95vw);
	margin: 1.75rem auto;

	.modal-content {
		border-radius: 4.8px;
		box-sizing: border-box;
		border: none;
		background-color: transparent;

		.modal-header {
			background: $brand-primary-blue-1;
			border-bottom: 1px solid #dee2e6;
			line-height: 24px;
			font-size: 20px;
			padding: 9px 1rem;
			border-top-left-radius: 4.8px;
			border-top-right-radius: 4.8px;

			.closeIcon {
				color: #99bde1;
				width: 26px;
				line-height: 24px;
				cursor: pointer;
			}

			.modal-title {
				line-height: 24px;
				font-size: 20px;
				color: #ffffff;
			}
		}

		.modal-body {
			background: #fff;
			padding: 0;

			.container {
				padding: 1rem;

				&__topContainer {
					padding-bottom: 0;

					#ssnRow {
						margin-top: 25px;
					}

					.requiredTag {
						margin-bottom: 10px;

						&__text {
							color: #212529;
						}
					}
				}

				.col-9 {
					flex: 0 0 auto;
					width: 50%;
				}

				.col-3 {
					flex: 0 0 auto;
					width: 16.66666667%;
				}

				.col-6 {
					flex: 0 0 auto;
					width: 33.33333333%;
				}

				@media (min-width: 768px) {
					.col-md-6 {
						flex: 0 0 auto;
						width: 50%;
					}
				}

				.row {
					position: relative;
					margin-bottom: 12px;

					&__spouseSsn {
						margin-bottom: 0;
					}
				}

				input[type='text'],
				input[type='email'] {
					height: 31px;
					font-size: 14px;
				}

				.flexCenter {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.informationText {
					font-size: 15px;
					line-height: 15px;
					font-weight: 700;
					color: $primary-font-color;
				}

				.asterisk {
					color: $primary-error;
				}

				.form-label {
					margin-bottom: 0.25rem;
					font-size: 14px;
				}

				.mobileSection {
					position: relative;

					label {
						font-size: 14px;
						margin-bottom: 0.25rem;
					}

					.inputCCMobile {
						display: flex;
						justify-content: flex-start;
						width: 100%;
						box-sizing: border-box;
						border: 1px solid $input-border-color;
						border-radius: 2px;
						box-sizing: border-box;

						input.form-control {
							border: 0px;
							height: 29px;
							width: 100%;
							padding: 6px 6px 6px 58px;
						}

						.flag-dropdown {
							background: #f0f1f1;
							border: none;
							border-right: 1px solid #898d91;
							border-radius: 0px;
							width: 23%;

							.selected-flag {
								width: 100%;
							}

							.arrow {
								height: 6px;
								width: 6px;
								border: solid #212529;
								border-width: 0 1px 1px 0;
								transform: rotate(45deg);
								-webkit-transform: rotate(45deg);
								top: 0;
								margin-top: 1px;
								margin-left: 6px;
							}

							.country-list {
								width: 216px;
								top: 21px;
								font-size: 14px;
								left: 1px;
								font-family: 'Mulish', sans-serif;

								.country {
									padding: 6px 0px 6px 7px;
								}
							}
						}
					}

					.userMobile {
						border: none;
						width: 75%;
						height: 29px;
						padding: 0.25rem 0.5rem;
						position: absolute;
						top: 26px;
						left: 24%;
					}
				}

				.form-control {
					background: #ffffff;
					border: 1px solid $input-border-color;
					border-radius: 2px;
					padding: 0.25rem 0.5rem;
				}

				.fieldErrorBorder {
					border: 1px solid $primary-error !important;
				}

				.fieldErrorMessage {
					color: $primary-error;
					padding: 4px 0 0;
					display: block;
					font-size: 14px;
					white-space: nowrap;
				}
			}
		}

		.modal-footer {
			background: #fff;

			.btn-secondary {
				background: #f0f1f1;
				border: 1px solid $input-border-color;
				border-radius: 2px;
				color: #202223;
				font-size: 1rem;
			}

			.btn-primary {
				background: $primary-blue;
				border: 1px solid $primary-blue;
				border-radius: 2px;
				color: #ffffff;
				font-size: 1rem;
			}

			.cancelBtn {
				width: 100px;
			}

			.actionBtn {
				width: 100px;
			}
		}

		.display-flex {
			display: flex;
		}

		.margin-right20 {
			margin-right: 20px;
		}

		.text-ellipsis {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}