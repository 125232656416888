@import '../../../styles/variables';


.draggable-list-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.4rem 0.5rem;
    font-size: 1rem;
    border-bottom: 2px solid $gray;
    cursor: grab;
    background-color: $white;
    &:nth-child(even) {
        background-color: rgba(240, 241,241, 0.5);
    }
}

.content-container {
    padding: 0 0.4rem;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grip-image {
    height: 100%;
}

.selected-card {
    background-color: rgba($primary-blue, .6) !important;
}

.grip-container svg {
    color: $brand-primary-blue-1;
}