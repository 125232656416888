.purchaseReturnTable {
    table-layout: fixed;
    color: #212529;
    font-size: max(min(2.1vw, 1rem), 12px);
    line-height: 1rem;
    border-spacing: 0px;
    margin-top: 1rem;
    margin-bottom: 10px;

    thead {
        border-bottom: 1px solid #C4C6C8;

        th {
            font-weight: 600;
            font-size: 14px;
            padding: 0.5rem 0 0.7rem;
        }

        .productCol {
            width: 20%;
        }

        .currentBalanceCol {
            width: 16%;
            text-align: right;
        }

        .quantityCol {
            width: 16%;
            text-align: right;
        }

        .pricePerReturnCol {
            width: 20%;
            text-align: right;
            padding-right: 1rem;
        }

        .totalCol {
            width: 12%;
            background: #F0F1F1;
            text-align: center;
        }

        .addCol {
            width: 16%;
            padding: 0 1rem;
        }
    }

    tbody {
        .purchaseRow {
            cursor: default !important;

            td {
                vertical-align: middle;
                padding: 0.3rem 0;
                font-weight: 600;
                font-size: 1rem;
                border-bottom-width: 0px;
            }

            .productColData {
                text-align: left;
            }

            .currentBalanceColData {
                text-align: right;
            }

            .negativeCurrentBalance {
                color: #CC4A43;
            }

            .quantityColData {
                text-align: right;

                input {
                    width: 66px;
                    height: 34px;
                    background: #FFFFFF;
                    border: 1px solid #898D91;
                    border-radius: 3.2px;
                    padding: 4px 8px;
                    text-align: right;
                }
            }

            .pricePerReturnColData {
                text-align: right;
                padding-right: 1rem;
            }

            .totalColData {
                background: #F0F1F1;
                text-align: center;
            }

            .addColData {
                padding-left: 16px;

                .addButton {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 38px;

                    .cartIcon {
                        margin-top: -6px;
                    }

                    .addText {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

.addedPurchaseTable {
    width: 313px;
    height: 339px;
    background: #F0F1F1;
    border: 1px solid #C4C6C8;
    border-radius: 4px;
    padding: 1rem;
    margin-top: 2rem;

    .header {
        display: flex;
        justify-content: space-between;

        .headerText {
            font-weight: 600;
            font-size: 1rem;
        }

        .headerClose {
            display: flex;
            align-items: center;

            svg {
                width: 1.2rem;
                height: 1.2rem;
                cursor: pointer;
            }
        }
    }

    .addedPurchaseTableDetails {
        padding: 1rem 1rem 0 0;

        .eachDetail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.6rem;

            label {
                font-weight: 700;
                font-size: 14px;
            }

            div {
                input {
                    padding: 4px 8px;
                    width: 112px;
                    height: 34px;
                    border: 1px solid #898D91;
                    border-radius: 3.2px;
                    text-align: right;
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
        }

        .eachDetailDisabled {
            color: #A6A9AC;
            pointer-events: none;

            div {
                input {
                    background: #E6F1F8;
                    color: #6B7075;
                }
            }
        }
    }
}

.profileClass {
    padding-top: 1.2rem;

    .profileInnerClass {
        font-family: 'Mulish';

        .eachRow {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 1rem 0;

            label {
                width: 50%;
            }

            label:nth-child(2) {
                padding-left: 0.5rem;
            }
        }

        .eachRow:first-child {
            padding: 0 0 0.6rem 0;
        }

        .productRowData {
            label:first-child {
                font-weight: 500;
                font-size: 16px;

                small {
                    font-weight: 600;
                    font-size: 16px;
                    color: #6B7075;
                    display: flex;
                    align-items: center;

                    span {
                        display: inline-block;
                        height: 21.5px;
                        border-left: 1px solid #C4C6C8;
                    }
                }
            }

            label:last-child {
                font-weight: 500;
                font-size: 16px;
            }
        }

        .salesTaxRowData {
            label:first-child {
                font-weight: 600;
                font-size: 14px;
            }

            label:last-child {
                font-weight: 500;
                font-size: 16px;
            }
        }

        .eachRow:last-child {
            background: #F0F1F1;
            margin-left: -0.3rem;
            padding: 1rem 0 1rem 0.3rem;

            label:first-child {
                font-weight: 700;
                font-size: 14px;
            }

            label:last-child {
                font-weight: 700;
                font-size: 16px;
            }
        }

        .profileHeader {
            font-weight: 600;
            font-size: 14px;
        }
    }
}