@import "../../../../styles/variables";

.image_card_wrapper {
    border: 1px solid rgba(0, 0, 0, 0.175);
    width: 330px;
    height: 130px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .image_card_content {
        padding: .5rem;
        max-width: 100%;
        max-height: 100%;
        display: block;
    }
}

.image_card_white_logo {
    background-color: #13294b;
}

.skeleton_loader {
    animation: skeleton-loading 1s linear infinite alternate;
    position: relative;
    width: 100%;
    height: 100%;
}

@keyframes skeleton-loading {
    0% {
        background-color: $brand-seconday-gray-35;
    }

    100% {
        background-color: $gray;
    }
}

.no_white_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    h1 {
        font-size: 2rem;
    }

    .no_white_logo_text {
        color: white;
    }
}