.company_logo_buttons_wrapper {
    display: flex;
    margin: 1rem 0;

    .company_logo_action_buttons {
        display: flex;
        color: #0973BA;
        border: 1px solid #0973BA;
        padding: 6.5px 24px;
        font-size: 14px;
        margin: 0px 1rem 0px 0px;

        svg {
            margin-right: 8px;
        }

    }
    :global .disabled_button {
        background-color: rgba(0, 0, 0, .1);
    }
}

.company_logo_description {
    margin-top: 1rem;
}