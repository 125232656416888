@import 'src/styles/variables';

.usersPagination {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .showPageNumber {
        color: $primary-blue;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 0px;
        margin-right: 0.5rem;

        li {
            list-style: none;
            padding: 6px 6px;
            border: 1px solid #F0F1F1;
            display: flex;
            align-items: center;
            margin-right: 1px;
            cursor: pointer;
            font-weight: bold;

            svg {
                width: 15px;
            }

            span {
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        li.currentPageNumber {
            background-color: $primary-blue;
            border: 1px solid $primary-blue;
            color: white;
            font-weight: normal;
            padding: 3px 9px;
            cursor: default;
        }

        li.paginationActionDisabled {
            color: #C4C6C8;
            background-color: #F0F1F1;
            cursor: not-allowed;
        }
    }


    .configurePagination {
        color: gray;
        white-space: nowrap;

        .pageSize {
            background-color: #FFFFFF;
            border: 1px solid #898D91;
            border-radius: 2px;
            padding: 4px 8px;
            outline: none;
            color: #565A5E;
            cursor: pointer;


            option {
                color: #565A5E;
            }
        }

        .paginationActionDisabled {
            color: #C4C6C8 !important;
            background-color: #F0F1F1;
            cursor: not-allowed;
            border: 1px solid #C4C6C8 !important;
        }

        .goToPage {
            border: 1px solid #898D91;
            border-radius: 2px;
            outline: none;
            padding: 4px 8px;
            width: 55px;
            color: #565A5E;
            text-align: center;
        }
    }
}

@media (max-width: 500px) {
    .usersPagination {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        .showPageNumber {
            margin-bottom: 9px;
            margin-right: 0px;
        }
    }
}