.fontWieght600 {
	font-weight: 600;
}

.mailMergeDownload {
	margin: 1rem 0;
	display: flex;
	align-items: center;

	svg {
		margin-left: 0.5rem;
		cursor: pointer;
	}
}

.webAddInDownload {
	margin: 1rem 0;
	display: flex;
	align-items: center;

	svg {
		margin-left: 0.5rem;
		cursor: pointer;
	}
}

.ticTieInfo {
	svg {
		margin-top: -3px;
	}
}

.ticTieCalculateDownload {
	margin: 1rem 0 0 0;
	display: flex;
	flex-direction: row;

	.downloadButton {
		width: auto;
		margin-right: 10px;
	}
}

.downloadButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	background: white;
	border: 1px solid #0973ba;
	color: #0973ba;
	border-radius: 2px;
	padding: 4px 8px;
	width: 120px;
	cursor: pointer;

	svg {
		width: 1rem;
		margin-top: -4px;
		margin-right: 0.5rem;
	}
}

.disabledButton {
	pointer-events: none;
	opacity: 0.5;
}
