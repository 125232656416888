.userResourceChangePopup .modal-dialog {
	.modal-content {
		.modal-header {
			height: 60px !important;
		}

		.modal-body {
			font-size: 16px !important;
			padding: 0.9rem 0rem 0.5rem 0.5rem !important;
		}
        .modal-footer {
			height:60px !important;
			padding: 0.8rem 0.5rem 0.5rem 7rem !important;

            .btn-primary {
				min-width: 100px !important;
				margin-top: -2px !important;
			}
        }
	}
}
