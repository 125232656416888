.searchMFAUsersEnableAll {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1.6rem 0;
    font-size: 1rem;

    .searchUser {
        input {
            outline: none;
            border: 1px solid #898D91;
            padding: 6px 12px;
            width: 255px;
            min-width: 160px;
            border-radius: 4px 0 0 4px;
        }

        .searchIcon {
            border: 1px solid #6B7075;
            background-color: white;
            padding: 6px 12px;
            margin-left: -1px;
            color: gray;
            border-radius: 0 4px 4px 0;
            cursor: default !important;

            svg {
                width: 1rem;
                margin-top: -2px;
            }
        }
    }

    .enableAllMFAUsers {
        font-size: 1rem;

        input {
            cursor: pointer;
        }

        label {
            font-size: 14px;
            margin-left: 0.6rem;
            cursor: pointer;
        }
    }
}

.mfaUsersListTable {
    table-layout: fixed;
    color: #212529;
    font-size: max(min(2.1vw, 1rem), 12px);
    line-height: 1rem;
    border-spacing: 0px;
    margin-bottom: 10px;

    thead {
        border-bottom: 2px solid #212529;

        th {
            font-weight: 400;
            font-size: 1rem;
            padding: 0.5rem 0.3rem;
        }

        .nameCol {
            width: 42%;
            cursor: pointer;
        }

        .emailCol {
            width: 42%;
            cursor: pointer;
        }

        .actionsCol {
            width: 16%;
            text-align: center;
        }
    }

    tbody {

        .userRow {
            cursor: default !important;

            td {
                vertical-align: middle;
                padding: 0.3rem
            }

            .nameColData,
            .emailColData {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .nameColData {
                font-weight: 600;
                font-size: 1rem;
            }

            .actionsColData {
                .switchEachUser {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: "Mulish";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #212529;

                    input {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.usersPagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;

    .paginationDetails {
        font-size: 1rem;
        font-weight: 400;

        .userFirst,
        .userLast,
        .userTotal {
            font-weight: 700;
        }
    }

    .showPageNumber {
        color: #0973BA;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 0px;
        margin-right: 0.5rem;

        li {
            list-style: none;
            padding: 6px 6px;
            border: 1px solid #F0F1F1;
            display: flex;
            align-items: center;
            margin-right: 1px;
            cursor: pointer;
            font-weight: bold;
        }

        .paginationActionDisabled {
            color: #C4C6C8;
            background-color: #F0F1F1;
            cursor: not-allowed;
        }

        .currentPageNumber {
            background-color: #0973BA;
            border: 1px solid #0973BA;
            color: white;
            font-weight: normal;
            padding: 3px 9px;
            cursor: default;
        }
    }
}