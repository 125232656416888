@import '../../../styles/variables';

.roles-permissions-container {
    width: 95%;
    height: calc(100vh - 20rem);
    margin: 2rem 0rem 0rem 1rem;
    font-family: $base-font-family;
    display: flex;
}

.roles-container {
    display: flex;
    flex: 1 1 auto;
    width: 30%;
}

.products-container {
    display: flex;
    flex: 1 1 auto;
    width: 30%;
}

.permissions-container {
    display: flex;
    flex: 1 1 auto;
    width: 40%;
}

.showRoles {
    display: flex;
}

.hideRoles {
    display: none;
}
