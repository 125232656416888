@import './brand_colors.css';

body {
  overflow: hidden !important;
}

.right {
  float: right !important;
}

.container-fluid {
  width: 100%;
  height: 100%;
  padding: 0;
}

#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#wrapper #content-wrapper {
  overflow-x: hidden;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 67px);
}

.btn i,
.btn-sm,
.btn-group-sm>.btn {
  border-radius: 0px !important;
}

.table-hover>tbody>tr:hover table-hover>tbody>tr {
  background-color: #edf9ff;
}

.table-hover>tbody>tr:hover {
  background-color: #edf9ff;
  cursor: pointer;
}

label,
table {
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-grey {
  background-color: #f6f6f6;
}

.my-settings-tabs {
  flex-wrap: nowrap;
}

.my-settings-tabs .nav-item a {
  color: rgba(0, 0, 0, .5);
  font-weight: 700;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 10px 15px;
}

.my-settings-tabs .nav-item a:hover {
  background-color: transparent;
  color: rgba(0, 0, 0, .5);
}

.settings-sidebar .nav-item a.active,
.settings-sidebar .nav-link.active {
  background-color: #fff;
}

.my-settings-tabs .nav-item:not(:last-child) {
  margin-bottom: 1rem;
}

.my-settings-tabs .tab-content>.tab-pane {
  height: 100%;
}

.my-settings-tabs .tab-content .description {
  font-size: 12px;
}

.settings-sidebar {
  width: 270px;
}

.settings-sidebar .nav-link {
  position: relative;
}

.settings-sidebar .nav-link.active:before {
  content: "";
  width: 6px;
  height: 100%;
  background-color: var(--brand-green);
  position: absolute;
  top: 0;
  left: 0;
}
