.bodySection {
    background: #FFF;
    color: #000000;
    border-bottom-left-radius: 4.8px;
    border-bottom-right-radius: 4.8px;
    padding: 1rem 1rem 2.3rem 1rem;

    .bodyHeading {
        font-family: Mulish;
        font-size: 1rem;
        font-weight: 700;
        line-height: 24px;
    }

    .bodyText {
        margin-top: 1rem;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        span {
            font-weight: 700;
        }
    }
}