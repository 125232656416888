@import 'src/styles/variables';

.inOfficeReturn {
	width: 70%;
	margin: 0 auto;
	margin-top: 8vh;

	&_heading {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
		margin-bottom: 16px;
	}

	&_infoText {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		color: #565a5e;
		margin-bottom: 24px;
	}

	&_formSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 46px;
	}

	.searchClient {
		display: flex;
		width: 360px;
		height: 38px;
		position: relative;

		input {
			outline: none;
			border: 1px solid $input-border-color;
			padding: 6px 30px 6px 12px;
			width: 100%;
			min-width: 160px;
			border-radius: 4px 0 0 4px;
			font-size: 16px;
		}
	}

	.searchIcon {
		border: 1px solid $input-border-color;
		background-color: white;
		width: 45px;
		padding: 10px;
		margin-left: -1px;
		color: gray;
		border-radius: 0 4px 4px 0;

		svg {
			width: 16px;
			height: 16px;
			vertical-align: unset;

			path {
				fill: #000 !important;
			}
		}
	}

	.crossIcon {
		position: absolute;
		top: 50%;
		right: 52px;
		transform: translateY(-50%);
		background: none;
		border: none;
		cursor: pointer;
	}

	.refreshIcon {
		width: 38px;
		height: 38px;
		padding: 10px;
		border: 1px solid #6b7075;
		border-radius: 2px;
		cursor: pointer;
	}

	@media (max-width: 1290px) {
		width: 80%;
	}
	
	@media (max-width: 1140px) {
		width: 92%;
	}
}

.inOfficeESigningTable {
	table-layout: fixed;
	color: $primary-font-color;
	font-size: 16px;
	line-height: 24px;
	border-spacing: 0px;
	margin-bottom: 10px;

	&_getStarted {
		align-items: center;
		background-color: $brand-primary-blue-2;
		border: 1px solid $brand-primary-blue-2;
		border-radius: 2px;
		color: #fff;
		display: flex;
		width: 140px;
		height: 38px;
		justify-content: center;
		padding: 6px 12px;
	}

	thead {
		border-bottom: 2px solid $primary-font-color;

		th {
			padding: 4px 4px 8px 4px;
			font-weight: 700;
		}

		th.taxYear {
			width: 30px;
		}

		th.clientId {
			width: 40px;
		}

		th.name {
			width: 110px;
		}

		th.returnStatus {
			width: 50px;
		}

		th.actions {
			width: 45px;
		}
	}

	tbody {
		vertical-align: middle;

		.clientsLoadingArea {
			vertical-align: middle;
			color: $primary-blue !important;
			cursor: default;

			.spinner-border {
				border-right-color: lightgray;
				border-bottom-color: lightgray;
				border-left-color: lightgray;
			}

			.clientsLoadingText {
				color: rgb(136, 136, 136);
				margin-left: 6px;
			}
		}

		tr {
			height: 70px;

			.awaitingESign {
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				background-color: #e6f1f8;
				border: 1px solid #075c95;
				color: #075c95;
				padding: 1px 8px;
				border-radius: 4px;
			}

			.partiallyESigned, .awaitingDraftReview {
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				background-color: #fbf0e7;
				border: 1px solid #a9570f;
				color: #a9570f;
				padding: 1px 8px;
				border-radius: 4px;
			}

			.groupNameColumn {
				display: flex;
				align-items: center;
				padding: 0;
				height: 24px;

				.leftSideName {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-right: 8px;
				}

				.icons {
					display: flex;
					align-items: center;
					max-width: 30px;
				}

				.width35 {
					max-width: calc(100% - 35px);
				}

				.groupIcon {
					max-width: 30px;
					height: auto;
					margin-bottom: 2px;
				}
			}
		}

		td {
			padding: 4px 4px 3px 4px;
			border-bottom: 1px solid #a6a9ac;

			&.ellipsis {
				width: 90%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	@media (max-width: 980px) {
		thead {
			th.taxYear {
				width: 22px;
			}
	
			th.clientId {
				width: 30px;
			}
	
			th.name {
				width: 70px;
			}
	
			th.returnStatus {
				width: 40px;
			}
		}
	}

	@media (max-width: 768px) {
		thead {
			th.name {
				width: 55px;
			}
		}
	}

	@media (max-width: 730px) {
		thead {	
			th.clientId {
				width: 25px;
			}
	
			th.name {
				width: 30px;
			}
		}
	}
}

.table > :not(caption) > * > * {
	box-shadow: none;
}

.inOfficePagination {
	display: flex;
	justify-content: space-between;
	padding-bottom: 12px;

	.showPageNumber {
		font-size: 14px;
		color: $primary-blue;
		display: flex;
		align-items: center;
		padding: 0;
		margin-bottom: 0px;
		margin-right: 0.5rem;

		li {
			list-style: none;
			padding: 6px 6px;
			border: 1px solid #f0f1f1;
			display: flex;
			align-items: center;
			margin-right: 1px;
			cursor: pointer;
			font-weight: bold;

			svg {
				width: 15px;
			}

			span {
				width: 15px;
				height: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		li.currentPageNumber {
			background-color: $primary-blue;
			border: 1px solid $primary-blue;
			color: white;
			font-weight: normal;
			padding: 3px 9px;
			cursor: default;
		}

		li.paginationActionDisabled {
			color: #c4c6c8;
			background-color: #f0f1f1;
			cursor: not-allowed;
		}
	}

	.configurePagination {
		color: gray;
		white-space: nowrap;
		font-size: 14px;

		.pageSize {
			background-color: #ffffff;
			border: 1px solid #898d91;
			border-radius: 2px;
			padding: 4px 8px;
			color: #565a5e;
			cursor: pointer;

			option {
				color: #565a5e;
			}
		}

		.paginationActionDisabled {
			color: #c4c6c8 !important;
			background-color: #f0f1f1;
			cursor: not-allowed;
			border: 1px solid #c4c6c8 !important;
		}

		.goToPage {
			border: 1px solid #898d91;
			border-radius: 2px;
			outline: none;
			padding: 4px 8px;
			width: 55px;
			color: #565a5e;
			text-align: center;
		}
	}
}

.inOfficeSigningConfirmationPopup .modal-dialog {
	.modal-content {
		.modal-footer {
			background: #fff;

			.btn-success {
				border: 1px solid #5c853a !important;
				background: #5c853a;
			}
			.btn-danger {
				border: 1px solid #cc4a43 !important;
				background: #cc4a43;
			}
		}
	}
}

@media (max-width: 500px) {
	.inOfficePagination {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;

		.showPageNumber {
			margin-bottom: 9px;
			margin-right: 0px;
		}
	}
}
