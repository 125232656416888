// FONT
$font-sm: 12px;
$font-lg: 16px;

// COLORS

$dark-blue: #13294B;
$gray: #C4C6C8;
$bg-grey: #E6EBF0;
$light-gray: #E7EAED;
$tooltip-yellow: #f7f5d2;

$primary-blue: #0973BA;
$primary-font-color: #212529;

$primary-error: #CC4A43;
// PRIMARY COLORS

$brand-primary-blue-1: #05386b;
$brand-primary-blue-2: #0973ba;
$brand-primary-green: #88c656;
$brand-primary-dark-blue: #002147;
$brand-primary-red: #dc3545;

// SECONDARY COLORS

$brand-secondary-blue: #13294b;
$brand-secondary-black: #101820;
$brand-secondary-green: #c7e995;
$brand-secondary-gray: #d9d9d6;
$brand-secondary-light-blue: #2f4362;

// OTHER COLORS
$input-border-color: #898D91;

$white: #fff;
$black: #212529;
$brand-secondary-black-30: #1018204d;
$brand-secondary-black-50: rgba(16, 24, 32, 0.5);
$brand-secondary-black-60: rgba(16, 24, 32, 0.6);
$error-color: #dc3545;
$brand-seconday-gray-35: rgba(217, 217, 214, 0.35);
$dark-gray: #737373;
$white-90: #ffffffcc;
$icon-normal-state: #929292;
$icon-hover-state: $dark-gray;
$brand-blue-10: rgba(5, 56, 107, 0.1);
$hover-blue-shadow: 0 0 4px 2px #0d6efd40;
$non-branded-light-blue_hover: #0d6efd40;
$orange: #ffdb6f;
$hover-orange: #ffaa00;
$placeholder-grey: $dark-gray;
$alert-blue: #355ABF;
$gray-600: #565A5E;
$success-green: #1F8747;
$bg-white: #F0F1F1;
$shamrock: #669440;
// FONT

$font-family: 'Mulish', sans-serif;
$base-font-size: 12px;
$font-size-md: 14px;
$heading-size-1: 18px;
$heading-size-2: 20px;

// BORDER

$gray-border: 1px solid #ced4da;
$selected-button-border: solid 6px $brand-primary-green;
$gray-border-shadow: inset 0 0px 0 #e1e1e1, inset 0 -1px 0 #e1e1e1;
$border-radius-5: 5px;

$base-font-family: 'Mulish', sans-serif;
