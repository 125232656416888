@import '../../../styles/variables';

.tooltip-container {
    display: inline-block;
    position: relative;
    margin: 0 .2rem;

    &:hover {
        cursor: pointer;
    }
}

.tooltip-image-container {
    font-size: .8rem;
    svg {
        height: .7rem;
        display: inline-block;
        vertical-align: baseline;
    }
}

.tooltip-text-container {
    position: absolute;
    display: inline;
    background: $tooltip-yellow;
    font-size: .7rem;
    padding: .5rem;
    font-weight: 500;
    border-radius: 3px;
    width: 12rem;
    top: -10px;
    left: 1.5rem;
    line-height: 12px;
    z-index: 10;
}
