.purchaseSummary {
    background: #F5F6FB;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .main {
        width: 756px;
        background: #FFFFFF;
        position: relative;
        padding: 3rem 3rem 1rem;

        .purchaseSuccessIcon {
            position: absolute;
            top: -3rem;
            left: 0;
            text-align: center;
            width: 100%;
        }

        .print {
            text-align: right;
            height: 1.5rem;

            .printIcon svg {
                margin-top: -5px;
                cursor: pointer;
            }

            .printText {
                text-decoration: underline;
                font-weight: 400;
                font-size: 1rem;
                color: #05386B;
                margin-left: 0.6rem;
                cursor: pointer;
            }
        }

        .thankyou {
            font-weight: 500;
            font-size: 2rem;
            color: #212529;
            text-align: center;
        }

        .invoice {
            text-align: center;
            margin-top: 0.9rem;
            font-size: 1rem;

            .invoiceText {
                font-weight: 400;
            }

            .invoiceNumber {
                font-weight: 700;
            }
        }

        .purchaseDetailsTable {
            width: 100%;
            margin-top: 2rem;

            table {
                width: 100%;

                thead {
                    border-bottom: 1px solid #C4C6C8;
                    padding-bottom: 0.6rem;

                    th {
                        padding-bottom: 0.3rem;
                        font-weight: 700;
                        font-size: 14px;
                    }

                    .productCol {
                        width: 32%;
                    }

                    .descriptionCol {
                        width: 40%;
                    }

                    .totalCol {
                        width: 28%;
                    }
                }

                tbody {
                    font-size: 1rem;

                    td {
                        font-size: 1rem;
                        padding: 0.6rem 0;
                    }

                    .productDataCol {
                        font-weight: 500;
                        color: #212529;
                    }

                    .descriptionDataCol {
                        font-weight: 600;
                        color: #6B7075;
                    }

                    .totalDataCol {
                        font-weight: 500;
                        color: #212529;
                        text-align: right;
                    }

                    .formatTotal {
                        background: #F0F1F1;
                        font-weight: 700;
                        padding: 1rem;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }

        .query {
            margin-top: 2rem;
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            color: #565A5E;
        }
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 1rem;
        background: #F0F1F1;

        div {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
        }

        .terms {
            span a {
                text-decoration: underline;
                cursor: pointer;
                color: #212529;
            }
        }
    }
}