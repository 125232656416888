@import 'src/styles/variables';

.userPermissionPagination {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .showPageNumber {
        color: $primary-blue;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 0px;
        margin-right: 0.5rem;

        li {
            list-style: none;
            padding: 6px 6px;
            border: 1px solid #f0f1f1;
            display: flex;
            align-items: center;
            margin-right: 1px;
            cursor: pointer;
            font-weight: bold;

            svg {
                width: 15px;
            }

            span {
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        li.currentPageNumber {
            background-color: $primary-blue;
            border: 1px solid $primary-blue;
            color: white;
            font-weight: normal;
            padding: 3px 9px;
            cursor: default;
        }

        li.paginationActionDisabled {
            color: #c4c6c8;
            background-color: #f0f1f1;
            cursor: not-allowed;
        }
    }

    .configurePagination {
        color: gray;
        white-space: nowrap;

        .pageSize {
            background-color: #ffffff;
            border: 1px solid #898d91;
            border-radius: 2px;
            padding: 4px 8px;
            outline: none;
            color: #565a5e;
            cursor: pointer;

            option {
                color: #565a5e;
            }
        }

        .paginationActionDisabled {
            color: #c4c6c8 !important;
            background-color: #f0f1f1;
            cursor: not-allowed;
            border: 1px solid #c4c6c8 !important;
        }

        .goToPage {
            border: 1px solid #898d91;
            border-radius: 2px;
            outline: none;
            padding: 4px 8px;
            width: 55px;
            color: #565a5e;
            text-align: center;
        }
    }
}

@media (max-width: 500px) {
    .userPermissionPagination {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        .showPageNumber {
            margin-bottom: 9px;
            margin-right: 0px;
        }
    }
}
