@import 'src/styles/variables';

.suiteModal {
    .modal-body {
        background: #fff;
        overflow-y: auto;

        .container {
            padding: 0;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;

            .disableMessage {
                padding: 0.5rem 0;
            }
        }
    }

    .modal-footer {
        background: #fff;

        .btn-secondary {
            background: #F0F1F1;
            border: 1px solid $input-border-color;
            border-radius: 2px;
            color: #202223;
            font-size: 1rem;
        }

        .btn-primary {
            background: $primary-blue;
            border: 1px solid $primary-blue;
            border-radius: 2px;
            color: #FFFFFF;
            font-size: 1rem;
        }
    }
}