.closeIcon {
    height: auto;
    width: 26px;
    color: #829BB5;

        &:hover {
            cursor: pointer;
        }
}

.modal_body_title {
    font-size: 16px;
    font-weight: 700;
}

.edit_primary_admin_wrapper {

    .modal_header {
        display: flex;
        align-items: center;

        :global .modal-title {
            font-size: 20px;
        }
    }

    .modal_content_wrapper {
        .modal_row {
            display: flex;
            align-items: center;
            margin: 24px 0;
        }
        .row_title {
            font-size: 14px;
            font-weight: 600;
            color: #212529;
            margin-right: 3rem;
        }

        .row_content {
            font-size: 16px;
            font-weight: 400;
            color: #212529;
            width: 85%;
            
            .email_address {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}

.selectUsers {
    width: 16rem;
}

.cancel_button {
    border-radius: 2px;
    padding: 6px 24px;
    color: #212529;
    border: 1px solid #898D91;
    background-color: #F0F1F1;
    margin: 0;

}

.update_button {
    border-radius: 2px;
    padding: 6px 24px;
    color: #fff;
    background-color: #0973BA;
    margin: 0;

    &.disabled_button {
        color: #fff;
    }
}
