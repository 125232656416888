@import 'src/styles/variables';

.content {
    width: 100%;
    height: 100%;
}

.userPermission {
    padding: 10px 20px 20px 20px;
    color: $primary-font-color;
    font-size: 14px;
    height: calc(100vh - 85px);
    overflow: hidden;
    overflow-y: scroll;
}

.userPermissionHeading {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 35px;
}
