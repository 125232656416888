@import 'src/styles/variables';

.userGroupAuditModal {
    display: flex !important;
    height: auto;
    max-height: 100%;
    top: 0vh;

    .modal-dialog {
        display: flex;
        width: 1090px;
        max-width: 1090px;
        margin: 1.75rem auto;

        .modal-content {
            border-radius: 4.8px;
            box-sizing: border-box;
            border: none;
            background-color: transparent;

            .modal-header {
                background: #FFF;
                border-bottom: 1px solid #DEE2E6;
                line-height: 24px;
                font-size: 20px;
                padding: 1rem;
                border-top-left-radius: 4.8px;
                border-top-right-radius: 4.8px;

                .closeIcon {
                    color: rgba(0, 0, 0, 0.5);
                    width: 26px;
                    line-height: 24px;
                    cursor: pointer;
                }

                .modal-title {
                    color: $primary-font-color;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 120%;
                }
            }

            .modal-body {
                background: #fff;
                overflow-y: auto;

                .container {
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    color: $primary-font-color;
                    padding: 0;
                    line-height: 150%;
                    margin-bottom: 1rem;                   
                }
                .marB03{
                    margin-bottom: 3px;
                }
                .padT07{
                    padding-top: 7px;
                }
                .marB10{
                    margin-bottom: 10px;
                }
                .padR30{
                    padding-right: 30px;
                }

                .clickableColumns {
                    cursor: pointer;
                }
                
                .sortIcon {
                    color: #6B7075;
                    font-size: 11px;
                    line-height: inherit;
                }
                
                .column {
                    display: flex;
                    align-items: center;
                
                    &__name {
                        width: auto;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: normal;
                    }
                }
                
                .self-end {
                    align-self: self-end;
                }
                
                .fixTableHead { 
                    overflow-y: auto; 
                    max-height: 420px; 
                  } 
                  .fixTableHead thead th { 
                    position: sticky; 
                    top: 0; 
                  }

                .userGroupAuditTable {
                    table-layout: fixed;
                    color: $primary-font-color;
                    font-size: max(min(2.1vw, 1rem), 12px);
                    line-height: 1rem;
                    border-spacing: 0px;
                    margin-bottom: 10px;
                
                    thead {
                        border-bottom: 2px solid $primary-font-color;
                
                        th {
                            padding: 4px 4px 5px;
                            font-weight: 400;
                        }
                
                        th.groupNameCol {
                            width: 35%;
                        }
                
                        th.changeCol {
                            width: 15%;
                        }
                
                        th.changedByCol {
                            width: 25%;
                        }
                
                        th.timeStampCol {
                            width: 25%;
                        } 

                        @media (max-width: 500px) {
                            th {
                                padding: 3px 2px 3px 3px;
                                font-weight: 700;
                            }
                
                            th.groupNameCol {
                                width: 35%;
                            }
                    
                            th.changeCol {
                                width: 15%;
                            }
                    
                            th.changedByCol {
                                width: 25%;
                            }
                    
                            th.timeStampCol {
                                width: 25%;
                            } 
                        }
                
                    }
                
                    tbody {
                        .usersLoadingArea {
                            vertical-align: middle;
                            color: $primary-blue !important;
                
                            .spinner-border {
                                border-right-color: lightgray;
                                border-bottom-color: lightgray;
                                border-left-color: lightgray;
                            }
                
                            .usersLoadingText {
                                color: rgb(136, 136, 136);
                                margin-left: 6px;
                            }
                        }
                
                        tr.userRow {
                            position: relative; 

                            td {
                                padding: 4px 4px 3px;
                                vertical-align: middle;
                            }
                
                            @media (max-width: 500px) {
                                td {
                                    padding: 3px 2px 0px 3px;
                                    vertical-align: middle;
                                }
                            }                
                           
                            td.changedByColData {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                
                            
                
                            .moreGroups {
                                color: $brand-primary-blue-1;
                                font-size: max(min(2.1vw, 14px), 12px);
                                cursor: pointer;
                                text-decoration: underline;
                                font-weight: 700;
                                display: inline-block;
                                overflow: hidden;
                                white-space: nowrap;
                                max-width: 21%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            } 
                            .group-badge {
                                margin-right: 5px;
                                padding: 1px 0.5vw;
                                font-size: max(min(2.1vw, 14px), 12px);
                                font-weight: 400;
                                border-radius: 4px;
                                display: inline-block;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            } 
                
                            .max-width26 {
                                max-width: 26%;
                            }
                
                            .max-width31 {
                                max-width: 31%;
                            }
                
                            .max-width48 {
                                max-width: 48%;
                            }
                
                            .max-width65 {
                                max-width: 65%;
                            }
                
                            .max-width98 {
                                max-width: 98%;
                            }
                
                            @media (max-width: 500px) {
                                .group-badge {
                                    margin-right: 2px;
                                    padding: 1px 2px;
                                    font-size: 12px;
                                    font-weight: 400;
                                    border-radius: 4px;
                                }                
                               
                            }
                
                        }
                    }
                }
            }            
        }
    }
}