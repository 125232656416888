@import 'src/styles/variables';

.modal850 {
    .modal-dialog {
        width: 850px;
        max-width: 850px;
    }
}

.modal800 {
    .modal-dialog {
        width: 800px;
        max-width: 800px;
    }
}

.modal692 {
	.modal-dialog {
		width: 692px;
		max-width: 692px;
	}
}

.modal600 {
    .modal-dialog {
        width: 600px;
        max-width: 600px;
    }
}

.modal500 {
    .modal-dialog {
        width: 500px;
        max-width: 500px;
    }
}

.suiteModal {
    .modal-dialog {
        display: flex;
        max-height: calc(100% - 3.5rem);
        margin: 1.75rem auto;

        .modal-content {
            border-radius: 4.8px;
            box-sizing: border-box;
            border: none;
            background-color: transparent;

            .lighttheme {
                background: white;

                .modal-title {
                    color: $primary-font-color;
                    font-weight: 700;
                }

                .closeIcon {
                    color: rgba(0, 0, 0, 0.5);
                }
            }

            .darktheme {
                background: #05386b;

                .modal-title {
                    color: white;
                    font-weight: 400;
                }

                .closeIcon {
                    color: #99bde1;
                }
            }

            .modal-header {
                border-bottom: 1px solid #DEE2E6;
                line-height: 24px;
                font-size: 20px;
                padding: 9px 1rem;
                border-top-left-radius: 4.8px;
                border-top-right-radius: 4.8px;

                .closeIcon {
                    width: 26px;
                    line-height: 24px;
                    cursor: pointer;
                }

                .modal-title {
                    line-height: 24px;
                    font-size: 20px;
                }
            }

        }
    }
}

.paymentStatus {
    .closeIcon {
        visibility: hidden;
    }
}