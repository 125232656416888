@import 'src/styles/variables';

.userPermissionHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 31px;

    .searchUser {
        display: flex;
        margin-right: 0.5rem;
        width: 350px;
        height: 38px;
        position: relative;
    
        input {
            outline: none;
            border: 1px solid $input-border-color;
            padding: 6px 30px 6px 12px;
            width: 100%;
            min-width: 160px;
            border-radius: 4px 0 0 4px;
        }
    }
    
    .searchIcon {
        border: 1px solid $input-border-color;
        background-color: white;
        width: 45px;
        padding: 10px;
        margin-left: -1px;
        color: gray;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
    
        svg {
            width: 16px;
            height: 16px;
            vertical-align: unset;
        }
    }
    
    .crossIcon {
        position: absolute;
        top: 50%;
        right: 52px;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        color: gray;
    }
}
