@import 'src/styles/variables';

.draggableListContainer {
    .draggableListCard {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.25rem 0.75rem 0.25rem 8px !important;
        font-size: 1rem;
        border-bottom: 1px solid $gray !important;

        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: -o-grab;
        cursor: -ms-grab;
        cursor: grab;

        &:nth-child(even) {
            background-color: rgba(240, 241, 241, 0.5);
        }

        &:hover {
            cursor: -webkit-grab;
            cursor: -moz-grab;
            cursor: -o-grab;
            cursor: -ms-grab;
            cursor: grab;
        }

        &:active {
            cursor: -webkit-grabbing !important;
            cursor: -moz-grabbing;
            cursor: -o-grabbing;
            cursor: -ms-grabbing;
            cursor: grabbing !important;
        }
    }

    .gripContainer svg {
        color: #05386B;
        margin-top: -3px;
        margin-left: 0.4rem;
    }

    .contentContainer {
        padding: 0 0.4rem;
        width: 94%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .selectedCard {
        background-color: rgba($primary-blue, .6) !important;
    }
}

.disabled {
    cursor: not-allowed !important;
}