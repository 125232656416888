@import 'src/styles/variables';

.confirmationPopup .modal-dialog {
	display: flex;
	box-shadow: 5px 5px 61px 0px;

	.modal-content {
		border-radius: 4.8px;
		box-sizing: border-box;
		border: none;
		background-color: transparent;

		.modal-header {
			background-color: #fff;
			color: black;
			border-bottom: 1px solid #dee2e6;
			line-height: 24px;
			font-size: 20px;
			padding: 9px 1rem;
			border-top-left-radius: 4.8px;
			border-top-right-radius: 4.8px;

			.closeIcon {
				color: rgba(0, 0, 0, 0.5);
				width: 26px;
				line-height: 24px;
				cursor: pointer;
			}

			.modal-title {
				line-height: 24px;
				font-size: 20px;
				color: #212529;
				font-weight: 700;
			}
		}

		.modal-body {
			height: fit-content;
			background-color: #fff;
			font-size: 14px;
			min-height: 5rem;
		}

		.modal-footer {
			background: #fff;

			.btn-secondary {
				background: #f0f1f1;
				border: 1px solid $input-border-color;
				border-radius: 2px;
				color: #202223;
				font-size: 1rem;
				font-weight: 400;
			}

			.btn-primary {
				border-radius: 2px;
				color: #ffffff;
				font-size: 1rem;
			}

			.btn-danger {
				font-size: 1rem;
				border: 1px solid $input-border-color;
				border-radius: 2px;
			}

			// bootstrap classname is incorrect
			.btn-disabled,
			.btn-disbaled {
				font-size: 1rem;
				border: 1px solid $input-border-color;
				border-radius: 2px;
				cursor: not-allowed;
				pointer-events: all;
			}
		}
	}
}

.popup_grid_container {
	padding: 0;
}

