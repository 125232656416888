@import 'src/styles/variables';

.changeContactPersonModal {
    background-color: rgba(0, 0, 0, 0.3);
}

.changeContactPersonModal .modal-dialog {
    
    .modal-content {
        .modal-header {
            background-color: #fff;
            border-bottom: 1px solid #DEE2E6;

            .closeIcon {
                color: rgba(0, 0, 0, 0.5);
                width: 26px;
                line-height: 24px;
                cursor: pointer;
            }

            .modal-title {
                color: #212529;
                font-weight: 700;
            }

        }

        .modal-body {
            .column-1 {
                .locationId {
                    margin-bottom: 5px;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 1.2;
                    letter-spacing: 0.2px;
                    text-transform: uppercase;
                    color: #A6A9AC;
                }

                .locationName {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 1.2;
                }

                .locationName-ellipsis {
                    max-width: 128px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .column-2 {
                .title {
                    margin-bottom: 6px;
                    font-weight: 600;
                }
            }

            .scrollableArea {
                margin-top: 15px;
                
                .rowContent {
                    border-bottom: 0.5px solid #A6A9AC;
                }
                
                .rowContent:last-child {
                    border-bottom: 0;
                }
            }

            .addScroll {
                margin-top: 15px;
                overflow: hidden;
                overflow-y: scroll;

                &_edit {
                    max-height: calc(100vh - 378px);
                }

                &_delete {
                    max-height: calc(100vh - 448px);
                }
            }

            .headerText {
                font-size: 16px;
                margin-bottom: 20px;
            }

            .warningMessage {
                border: 1px solid #AB7F2D;
                background-color: #FBF5EB;
                padding: 16px;
                border-radius: 4px;
                display: flex;
                align-items: center;

                .icon {
                    width: 16px;
                    margin-right: 12px;
                }

                .text {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;

                    .bold {
                        font-weight: 700;
                    }
                }
            }

            .saveUserFieldErrorMessage {
                color: $primary-error;
            }
        }

        .modal-footer {
            background: #fff;

            .btn-secondary {
                background: #F0F1F1;
                border: 1px solid $input-border-color;
                border-radius: 2px;
                color: #202223;
                font-size: 1rem;
                width: 100px;
            }

            .btn-primary {
                background: $primary-blue;
                border: 1px solid $primary-blue;
                border-radius: 2px;
                color: #FFFFFF;
                font-size: 1rem;
            }

            .btn-danger {
				border: 1px solid #CC4A43 !important;
				background: #CC4A43;
			}
        }
    }

    .rowContentDropdown {
        width: 225px;
    }
}

.flex-column-justify-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
