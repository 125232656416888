/* These utilities are same like bootstrap utilities with better namings. this is to avoid problems in bootstrap version migrations*/

/* MARGINS */
.margin-0 {
    margin: 0 !important;
}

.margin-right-4 {
    margin-right: 1rem !important;
}

.margin-left-3 {
    margin-left: .75rem !important;
}

.padding-4 {
    padding: 1rem !important;
}

.padding-top-bottom-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.padding-top-bottom-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

/* HEIGHT - WIDTH */

.height-100-percent {
    height: 100% !important;
}

.width-100-percent {
    width: 100% !important;
}

/* CUSTOM MODAL */

.custom-bootstrap-modal .modal-content {
    max-height: 90vh;
    border: 0;
}

.custom-bootstrap-modal .modal-header {
    background-color: var(--brand-dark-blue);
    color: #fff;
    border-radius: 0;
    font-size: 20px;
}

.custom-bootstrap-modal .modal-body {
    height: calc(100% - 105px);
    overflow: auto;
}

.custom-bootstrap-modal .modal-footer button {
    font-size: 14px;
}

.custom-bootstrap-modal .modal-footer.without-border {
    border: none;
}

.custom-bootstrap-modal .modal-footer.space-between {
    justify-content: space-between;
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #ced4da;
    background-image: none;
    box-shadow: inherit;
    padding-right: inherit;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
    padding-right: inherit;
}