@import '../../../../styles/variables';

.logo_upload_modal {
    font-family: 'Mulish', sans-serif;

    :global .modal-dialog {
        .modal-header {
            .modal-title {
                font-size: 20px;
            }
        }
    }

    :global .modal-content {
        width: 600px;
        height: auto;
    }

    :global .modal-body {
        padding: 0;
    }

    :global .modal-footer {
        margin-top: 1rem;
    }

    :global .alert {
        border-radius: 0;
    }

    .logoUploadAlert {
        display: flex;
        align-items: center;

        .logoUploadAlertSpan {
            display: inline-flex;
            margin-right: 5px;
            margin-top: -3px;
        }

    }

    .logo_upload_modal_description {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        margin-bottom: 1.5rem;
    }

    .logo_upload_modal_closeIcon {
        height: auto;
        width: 26px;
        color: #829bb5;

        &:hover {
            cursor: pointer;
        }
    }

    .logo_upload_modal_upload_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo_upload_modal_upload_action_section {
        display: grid;
        width: 330px;
        grid-template-columns: auto auto;
        margin-top: 1rem;
    }

    .logo_upload_modal_upload_button {
        display: flex;
        align-self: center;

        :global .button {
            margin: 0;
            border: 1px solid $primary-blue;
            color: $primary-blue;
            padding: .2rem .7rem;
            font-size: 14px;
            width: 100px;

            svg {
                margin-right: .5rem;
            }
        }
    }

    .logo_upload_modal_checkbox {
        display: flex;
        justify-self: flex-end;
        align-self: center;

        label {
            font-size: 16px;
            font-weight: 500;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(21, 22, 23, 1);

            input {
                margin-right: .3rem;
            }
        }
    }
}