@import 'src/styles/variables';

.searchAddExport {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.3rem 0;

	.searchUser {
		display: flex;
		margin-right: 0.5rem;
		height: 38px;

		input {
			outline: none;
			border: 1px solid $input-border-color;
			padding: 6px 12px;
			width: 26vw;
			min-width: 160px;
			border-radius: 4px 0 0 4px;
		}
	}

	@media (max-width: 500px) {
		.searchUser {
			margin-right: 0px;
			width: 100%;
			display: flex;
			justify-content: space-between;

			input {
				width: 100%;
			}
		}
	}

	.searchIcon {
		border: 1px solid $input-border-color;
		background-color: white;
		padding: 6px 12px;
		margin-left: -1px;
		color: gray;
		border-radius: 0 4px 4px 0;
		cursor: default !important;

		svg {
			width: 1rem;
		}
	}

	.addexport {
		display: flex;
		font-size: max(min(2.3vw, 1rem), 14px);

		.addUser {
			border: 1px solid $primary-blue;
			background-color: $primary-blue;
			padding: 6px 12px;
			height: 38px;
			border-radius: 2px;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 125px;
			margin-left: 10px;

			svg {
				width: 21px;
				height: 24px;
				margin-right: 7px;
			}
		}

		.exportUserDetails {
			border: 1px solid #6b7075;
			background-color: rgba(255, 255, 255, 1e-6);
			padding: 10px;
			height: 38px;
			color: $primary-font-color;
			border-radius: 2px;
			margin-left: 10px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		.exportDisabled {
			opacity: 0.65;
			cursor: not-allowed;
		}

		.exportIPLoader {
			color: $primary-blue;
			width: 16px;
			height: 16px;
			border: 2px solid currentColor;
			border-right-color: transparent;
		}
	}

	@media (max-width: 500px) {
		.addexport {
			display: flex;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 0.5rem;

			button {
				display: flex;
				align-items: center;
				width: 48%;
				justify-content: center;
			}
		}
	}
}

@media (max-width: 500px) {
	.searchAddExport {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		padding: 10px 0;
	}
}
