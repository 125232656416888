.header_design_modal {
    font-family: 'Mulish', sans-serif;

    :global .modal-content {
        width: 600px;
    }

    :global .modal-dialog {
        .modal-header {
            justify-content: space-between;

            .modal-title {
                font-size: 20px;
            }
        }
    }

    .headerDesignOverlayCancel {
        color: rgba(32, 34, 35, 1) !important;
    }

    .header_design_section_header {
        .header_design_section_header_title {
            font-size: 1rem;
            font-weight: 700;
        }

        .header_design_section_header_color {
            display: grid;
            grid-template-columns: auto auto auto auto;
            margin-top: 12px;

            .header_design_section_color_description {
                display: grid;
                grid-template-columns: auto auto auto auto auto auto;
                position: relative;

                .header_design_section_color_title {
                    font-size: 14px;
                    font-weight: 400;
                    display: flex;
                    align-self: center;
                }

                .header_design_section_color_display {
                    height: 30px;
                    width: 30px;
                    border: 1px solid #000;
                    border-radius: 4px;
                }
            }
        }
    }

    input[type="color"]::-moz-color-swatch {
        border: none;
        border-radius: 2px;
    }

    input[type="color"]::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 2px;
    }

    input[type="color"]::-webkit-color-swatch {
        border: none;
        border-radius: 2px;
    }

    .header_design_section_preview {
        margin-top: 1rem;

        .header_design_section_preview_header {
            font-size: 16px;
            font-weight: 700;
        }

        .header_design_section_preview_section {
            margin-top: .5rem;
            padding: .5rem;
            display: flex;
            justify-content: space-between;
        }

        .preview_company_section {
            font-size: 14px;
            max-height: 20px;
            height: 20px;
            display: flex;
            align-items: center;

            img {
                width: auto;
                height: 100%;
            }
        }

        .contact_div {
            display: flex;
            align-items: center;
            font-size: 14px;

            svg {
                height: 18px;
                width: 18px;
                margin-right: .2rem;
            }
        }
    }
}

.company_logo_div {
    max-width: 75px;
    max-height: 22px;
    margin: auto;
    margin-bottom: .5rem;
}

.header_design_modal_closeIcon {
    height: auto;
    width: 26px;
    color: #829bb5;

    &:hover {
        cursor: pointer;
    }
}

.app_footer {
    height: 25px;
    background-color: #f0f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;

    .copyright {
        color: #212529;
        font-size: 10px;
        font-weight: 400;
        line-height: 1.5;
    }

    .privacy_policy {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #212529;

        a {
            font-size: 10px;
            font-weight: 400;
            line-height: 1.5;
            text-decoration: none;
            color: #212529;
        }

        .hyphen {
            margin: 0 5px;
        }
    }
}

.welcome_page {
    padding-left: 15%;
    padding-right: 15%;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
}

.welcome_text {
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
}

.get_started_button {
    width: 150px;
    height: 27px;
    border-radius: 5rem !important;
    font-size: 10px;
    padding: 5px !important;
    margin: 15px 0;
}

.tax_year_header {
    color: var(--body-text-body-color, #212529);
    font-family: 'Mulish', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.client_name_header {
    color: var(--body-text-body-color, #212529);
    font-family: 'Mulish', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.prepared_by_title {
    color: var(--body-text-body-color, #212529);
    font-family: Mulish;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.client_details_header {
    margin-top: 12px;
}
