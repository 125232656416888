@import 'src/styles/variables';

.message_note {
    display: flex;
    height: 63px;
    padding: 16px 16px 16px 16px;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    border-left: 5px solid $brand-primary-blue-2;
    background: var(--sapphire-100, #E6F1F8);
    display: flex;
    align-items: center;
}

.message_note .text {
    background: var(--HitBox, rgba(255, 255, 255, 0.00));
    color: var(--body-text-body-color, #212529);
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.message_note .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    background: var(--HitBox, rgba(255, 255, 255, 0.00));
    padding-right: 10px;
}

.message_note ul {
    margin-bottom: 0px;
}

.message_note li {
    margin-left: -15px;
}